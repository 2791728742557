<template>
    <div
        class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile"
    >
        <div
            class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm"
        >
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        class="kt-svg-icon"
                    >
                        <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <rect
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                            />
                            <path
                                d="M19.366142,13.9305937 L17.2619853,15.6656848 C15.9733542,14.1029531 14.0626842,13.1818182 11.9984835,13.1818182 C9.94104045,13.1818182 8.03600715,14.0968752 6.74725784,15.6508398 L4.64798148,13.9098472 C6.44949126,11.7375997 9.12064835,10.4545455 11.9984835,10.4545455 C14.8857906,10.4545455 17.5648042,11.7460992 19.366142,13.9305937 Z M23.5459782,10.4257575 L21.4473503,12.1675316 C19.1284914,9.37358605 15.6994058,7.72727273 11.9984835,7.72727273 C8.30267753,7.72727273 4.87785708,9.36900008 2.55893241,12.1563207 L0.462362714,10.4120696 C3.29407133,7.00838857 7.48378666,5 11.9984835,5 C16.519438,5 20.7143528,7.01399004 23.5459782,10.4257575 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                            />
                            <path
                                d="M15.1189503,17.3544974 L13.0392442,19.1188213 L11.9619232,20 L10.9331836,19.1485815 L8.80489611,17.4431757 C9.57552634,16.4814558 10.741377,15.9090909 11.9984835,15.9090909 C13.215079,15.9090909 14.347452,16.4450896 15.1189503,17.3544974 Z"
                                id="Oval-21"
                                fill="#000000"
                            />
                        </g>
                    </svg>
                </span>
                <h3
                    class="kt-portlet__head-title"
                >{{ $t("troubleshooting_gatewayChecking") }}</h3>
            </div>
            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-wrapper">
                    <div class="kt-portlet__head-toolbar">
                        <div class="row align-items-center">
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                <div class="kt-input-icon kt-input-icon--left">
                                    <input type="text" :placeholder="$t('common_searchbyB&F')" @keyup="onSearch" v-model="searchedValue" id="act_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                        <span><i class="la la-search"></i></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin emptyList component-->
        <app-emptylist targetObjectName="BEACONSHEALTH_LIST" mainTextKey="troubleshooting_emptyGatewayChecking" subTextKey="troubleshooting_emptyGatewayCheckingSubTitle" imgName="empty_gateway.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
        <!--end emptyList component-->
        <div class="kt-portlet__body kt-portlet__body--fit">
            <!--begin: Datatable -->
            <div class="kt-datatable" id="gatewayChecking_datatable"></div>
            <!--end: Datatable -->
        </div>
        <app-gatewaycheckingmodal :gatewaysWithWrongWallDistance="gatewaysWithWrongWallDistance" :excellentPercent="excellentPercent" :satifiedPercent="satisfiedPercent" :floorConfigurationPercent="floorConfigurationPercent" :minLimit="minLimit" :gtwFloorElevation="gtwFloorElevation" :maxLimit="maxLimit" :buildingName="buildingName" :floorName="floorName" :radiationDensityByGateway="radiationDensityByGateway" :nbGateways="nbGateways" :gatewaysCheckingResponse="gatewaysCheckingResponse" :gatewaysNotWellLocated="gatewaysNotWellLocated" :siteId="siteId" :gatewaysWithWrongDistance="gatewaysWithWrongDistance" :floorId="floorId"></app-gatewaycheckingmodal>
        <app-gatewayscheckinghealthmodal :floorId="floorId" :gatewaysHealthOfFloorTable="gatewaysHealthOfFloorTable" :buildingName="buildingName" :floorName="floorName" :siteId="siteId" :nbGateways="nbGateways"></app-gatewayscheckinghealthmodal>
        <app-heatmapgatewayanalysemodal :buildingId="buildingId" :lastRssiStrengthOfGatewaysOfFloor="lastRssiStrengthOfGatewaysOfFloor" :floorId="floorId" :heatmapResponse="heatmapResponse" :buildingName="buildingName" :floorName="floorName" :siteId="siteId" :nbGateways="nbGateways"></app-heatmapgatewayanalysemodal>
    </div>
</template>

<script>
import {mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import GatewaysCheckingModal from "../modals/gatewayscheckingmodal.vue";
import GatewaysCheckingHealthModal from "../modals/gatewaycheckinghealthmodal.vue";
import HeatmapGatewayAnalyseModal from "../modals/heatmapgatewayanalysemodal.vue";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            isEmptyList: true,
            gatewayCheckingTable: null,
            searchedValue : "",
            minLimit : "",
            maxLimit : "",
            floorName : "",
            buildingName : "",
            radiationDensityByGateway : "",
            nbGateways : "",
            gtwFloorElevation : {},
            gatewaysNotWellLocated : [],
            gatewaysWithWrongDistance : [],
            gatewaysWithWrongWallDistance : [],
            gatewaysCheckingResponse : [],
            gatewaysHealthOfFloorTable : [],
            nbGatewayUp : 0,
            nbGatewayDown : 0,
            heatmapResponse : {},
            floorId : "",
            lastRssiStrengthOfGatewaysOfFloor : [],
            buildingId :"",
            refOfIssuesPercent: {
                UNSATISFIED_FLOOR_ELEVATION: 8,
                NB_GATEWAY_NOT_SUFFICIENT: 21,
                GATEWAY_NOT_LOCATED_INSIDE_ONE_INDOOR_AREA : 21,
                UNSATISFIED_GATEWAY_DENSITY : 21,
                UNSATISFIED_DISTANCE_BETWEEN_GATEWAYS : 8,
                UNSATISFIED_DISTANCE_BETWEEN_GATEWAYS_AND_WALLS : 21
            },
            satisfiedPercent: 84,
            excellentPercent: 92,
            floorConfigurationStatus : {},
            floorConfigurationPercent : 0

        };
    },
    created: function() {
        console.log("Component(gatewaychecking)::created() - called");
    },
    mounted: function() {
        console.log("Component(gatewaychecking)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(gatewaychecking)::destroyed() - called");
        this.gtwFloorElevation = {};
        this.gatewaysNotWellLocated = [];
        this.gatewaysWithWrongDistance = [];
        this.gatewaysWithWrongWallDistance = [];
        this.gatewaysCheckingResponse = [];
        this.gatewaysHealthOfFloorTable = [];
        this.heatmapResponse = {};
        this.lastRssiStrengthOfGatewaysOfFloor = [];
        this.floorConfigurationStatus = {};
    },
    props: {
        gatewaysChecking : {
            type: Array,
            required: true
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".

        gatewaysChecking() {
            console.log("Component(gatewaychecking)::watch(gatewaysChecking) called with : ", this.gatewaysChecking);
            for(let data of this.gatewaysChecking){
                if(data && data.hasOwnProperty('gatewaysCheckingResponse')){
                    let floorId = data.floorId;
                    let floorConfigurationPercent = this.onChangeConfigurationStatus(data);
                    this.floorConfigurationStatus[floorId] = floorConfigurationPercent;
                }
            }
            this.initKtDataTable(this.gatewaysChecking);
        },

        user: function(user) {
            if (user) {
                console.log("Component(gatewaychecking)::watch(user) called with : ", user);
                this.initKtDataTable(this.gatewaysChecking);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --teAnoth
        ...mapGetters(["user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        // Function called when user wants to search a specific value
        onSearch() {
            if (this.gatewayCheckingTable) {
                const searchedValueUpperCase = this.searchedValue.toUpperCase();
                // 1. Search in "floorName" field of gateway checking table
                const dataFoundByFloorName = _.filter(this.gatewaysChecking, gateway => gateway && gateway.floorName && gateway.floorName.toUpperCase().includes(searchedValueUpperCase));
                // 2. Search in "buildingName" field of gateway checking table
                const dataFoundByBuildingName = _.filter(this.gatewaysChecking, gateway => gateway && gateway.buildingName && gateway.buildingName.toUpperCase().includes(searchedValueUpperCase));

                const dataFound = _.unionBy(dataFoundByFloorName, dataFoundByBuildingName);
                if (dataFound && dataFound.length > 0) {
                    // Reload datatable with only rows which contain searchedValue
                    this.initKtDataTable(dataFound);
                } else {
                    // No searchedValue found => Display empty datatable
                    this.gatewayCheckingTable.search(this.searchedValue, "unknown");
                }
            }
        },

        // Function used to adapt the configuration status for the level of the issue that you have on your site deployment
        onChangeConfigurationStatus(data){
            let percentOfIssueResolved = 0;
            // look if the gateway checking response has the key issue and adapt in function the configuration status
            for (let [key, value] of Object.entries(this.refOfIssuesPercent)) {
                if (!data.gatewaysCheckingResponse.includes(key)) {
                    percentOfIssueResolved += value;
                }
            }
            return percentOfIssueResolved;
        },

        //Go to Indoor Area edit page
        onCreateIndoorArea: function() {
            this.$router.push({
                name: "indoorAreas",
                params: { siteId: this.siteId }
            });
        },
        //Go to Gateway Location edit page
        onEditGatewayLocation: function() {
            this.$router.push({ name: "gatewaysLocation", params: { siteId: this.siteId } });
        },

        registerEventsOnGatewayCheckingTable() {
            var self = this;
            $("#gatewayChecking_datatable").on("kt-datatable--on-layout-updated", () => {
                $(".checkingIssue").off().on("click", function() {
                    console.log("Component(gatewaychecking)::registerEventsOnGatewayCheckingTable(checkingIssue) clicked. ");
                    self.minLimit = $(this).attr("data-minLimit");
                    self.maxLimit = $(this).attr("data-maxLimit");
                    self.floorName = $(this).attr("data-floorName");
                    self.nbGateways = $(this).attr("data-nbGateway");
                    self.radiationDensityByGateway = $(this).attr("data-radiationDensityByGateway");
                    self.floorCoverageDensity = $(this).attr("data-floorCoverageDensity");
                    self.buildingName = $(this).attr("data-buildingName");
                    self.floorId = $(this).attr("data-floorId");
                    // update of the percent of good configuration for the floor given by is id
                    self.floorConfigurationPercent = self.floorConfigurationStatus[self.floorId];

                    //take back all the table or Object we need
                    const floorId = $(this).attr("data-floorid");
                    for (let gtw of self.gatewaysChecking) {
                        if (gtw.floorId === floorId && gtw.hasOwnProperty('gatewaysNotWellLocated')){
                            self.gatewaysNotWellLocated = gtw.gatewaysNotWellLocated;
                        }
                        if (gtw.floorId === floorId && gtw.hasOwnProperty('gatewaysCheckingResponse')){
                            self.gatewaysCheckingResponse = gtw.gatewaysCheckingResponse;
                        }
                        if (gtw.floorId === floorId && gtw.hasOwnProperty('gtwFloorElevation')) {
                            self.gtwFloorElevation = gtw.gtwFloorElevation;
                        }
                        if (gtw.floorId === floorId && gtw.hasOwnProperty('gatewaysWithWrongDistance')){
                            self.gatewaysWithWrongDistance = gtw.gatewaysWithWrongDistance;
                        }
                        if (gtw.floorId === floorId && gtw.hasOwnProperty('gatewaysWithWrongWallDistance')){
                            self.gatewaysWithWrongWallDistance = gtw.gatewaysWithWrongWallDistance;
                        }

                    }
                    // Hide and destroy element’s tooltip
                    $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                    //show the two limits of coverag
                    // Show the modal
                    $("#gatewayscheckingModal").modal("show");
                });

                $(".checkingStatutCoverage").off().on("click", function() {

                    console.log("Component(gatewaychecking)::registerEventsOnGatewayCheckingTable(checkingIssue) clicked. ");
                    self.floorId = $(this).attr("data-floorid");
                    self.floorName = $(this).attr("data-floorName");
                    self.nbGateways = $(this).attr("data-nbGateway");
                    self.buildingName = $(this).attr("data-buildingName");

                    for (let gtw of self.gatewaysChecking) {
                        // Loop to find the data of gateway health
                        if (gtw.floorId === self.floorId && gtw.hasOwnProperty('gatewaysHealthOfFloorTable')){
                            // Get only gateways which has located
                            self.gatewaysHealthOfFloorTable = gtw.gatewaysHealthOfFloorTable.filter((gateway) => gateway.floor && gateway.building && gateway.location);
                            break;
                        }
                    }
                    // Hide and destroy element’s tooltip
                    $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                    //show the two limits of coverag
                    // Show the modal
                    $("#gatewayscheckinghealthModal").modal("show");
                });

                $(".checkingStatutHeatmap").off().on("click", function() {

                    console.log("Component(gatewaychecking)::registerEventsOnGatewayCheckingTable(checkingStatutHeatmap) clicked. ");
                    self.floorId = $(this).attr("data-floorid");
                    self.floorName = $(this).attr("data-floorName");
                    self.nbGateways = $(this).attr("data-nbGateway");
                    self.buildingName = $(this).attr("data-buildingName");
                    self.buildingId = $(this).attr("data-buildingId");
                    for (let floorData of self.gatewaysChecking) {
                        if (floorData.floorId === self.floorId && floorData.hasOwnProperty('heatmapResponse')){
                            self.heatmapResponse = floorData.heatmapResponse;
                        }
                        if (floorData.floorId === self.floorId && floorData.hasOwnProperty('lastRssiStrengthOfGatewaysOfFloor')){
                            self.lastRssiStrengthOfGatewaysOfFloor = self.sortRssiTable(floorData.lastRssiStrengthOfGatewaysOfFloor);
                        }
                    }
                    // Hide and destroy element’s tooltip
                    $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                    //show the two limits of coverag
                    // Show the modal
                    $("#heatmapgatewayanalyseModal").modal("show");
                });

                $(".editIndoorAreaButton")
                    .off()
                    .on("click", function() {
                        console.log("Component(gatewaychecking)::registerEventsOnGatewayCheckingTable(editIndoorAreaButton) clicked. ");
                        const floorId = $(this).attr("data-floorid");
                        const keyStrorage = self.siteId + "_indoorAreasDefinition_lastDisplayedFloorId";
                        localStorage.setItem(keyStrorage, floorId)
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit Indoor Area vue
                        self.onCreateIndoorArea();
                    });
                $(".editGatewayLocationButton")
                    .off()
                    .on("click", function() {
                        console.log("Component(gatewaychecking)::registerEventsOnGatewayCheckingTable(editGatewayLocationButton) clicked.");
                        const floorId = $(this).attr("data-floorid");
                        const keyStrorage = self.siteId + "_gatewaysLocation_lastDisplayedFloorId";
                        localStorage.setItem(keyStrorage, floorId)
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit gateway location vue
                        self.onEditGatewayLocation();
                    });
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");
                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },

        initKtDataTable(dataSource) {
            var self = this;

            if (this.gatewaysChecking != null) {

                // If gateway table has been already build, destroy the table before creating a fresh instance !
                if (this.gatewayCheckingTable) {
                    $("#gatewayChecking_datatable").KTDatatable().destroy();
                }

                // According to source list length, show datatable or empty list icon+text
                if (this.gatewaysChecking.length > 0) {
                    // There is data => Hide empty list icon+text
                    this.isEmptyList = false;

                    const options = {
                        data: {
                            type: "local",
                            source: commonVueHelper.preventHtmlInjection(dataSource),
                            pageSize: 5,
                            saveState: {
                                webstorage: false
                            }
                        },
                        layout: commonVueHelper.getLayoutOptions(),
                        translate: commonVueHelper.getTranslateOptions(),
                        toolbar: commonVueHelper.getToolbarOptions(),
                        sortable: !0,
                        pagination: !0,
                        columns: [
                            {
                                field: "floorName",
                                title: i18n.t("gtw_bleGatewayFloorNameLabel"),
                                textAlign: "center",
                                overflow: "visible",
                                autoHide: false,
                            },
                            {
                                field: "buildingName",
                                title: i18n.t("gtw_bleGatewayBuildingNameLabel"),
                                textAlign: "center",
                                overflow: "visible",
                            },
                            {
                                field: "floorCoverageDensity",
                                title: i18n.t("troubleshooting_floorCoverage"),
                                textAlign: "center",
                                sortable: false,
                                template : function(row) {
                                    if(row.floorCoverageDensity && row.floorCoverageDensity!=0){
                                        return row.floorCoverageDensity + " m²";
                                    }else{
                                        return "-";
                                    }
                                }
                            },
                            {
                                field: "radiationDensityByGateway",
                                title: i18n.t("troubleshooting_radiationDensityByGateway"),
                                textAlign: "center",
                                sortable: false,
                                width: 150,
                                template : function(row){
                                    if(row.radiationDensityByGateway && row.radiationDensityByGateway > 0 ){

                                        return Math.round(row.radiationDensityByGateway) + " m²"; // on fait ici l'arrondi à l'entier le plus proche
                                    }else{
                                        return "-";
                                    }
                                }
                            },
                            {
                                field: "nbGateways",
                                title: i18n.t("troubleshooting_gatewayNumber"),
                                textAlign: "center",
                                sortable: false,
                                width: 150,
                                template : function(row){

                                    if(row.nbGateways && row.nbGateways != 0){
                                        return row.nbGateways;
                                    }
                                    else{
                                        return "-";
                                    }
                                }
                            },
                            {
                                field: "heatmapResponse",
                                title: i18n.t("troubleshooting_heatmapAnalyseTitle"),
                                textAlign: "center",
                                sortable: false,
                                autoHide: false,
                                width: 150,
                                template : function(row){
                                    let message = `<div style="
                                                    display: flex;
                                                    flex-direction: row;
                                                    width: auto
                                                ">`
                                    if(row.heatmapResponse && row.heatmapResponse.floorHeatmapStatus == "SATISFIED"){
                                        message +=
                                            `<div style="flex: 1; width: auto; margin-left : 0em; margin-top: auto; margin-bottom : auto "><span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">`+
                                                    i18n.t("troubleshooting_checkingOK") +
                                                    `</span>
                                            </div>
                                            <div style="width: auto; margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("analytics_details") + `"type="button" data-floorName="` + row.floorName  + `"data-floorid="` + row.floorId + `"data-nbGateway="` + row.nbGateways + `"data-buildingName="` + row.buildingName + `"data-buildingId="`+ row.buildingId +
                                                    `" class="btn btn-label-brand btn-bold btn-circle btn-icon checkingStatutHeatmap ml-2" sytle = "height: 1rem; width: 1rem;">
                                                        <i class="flaticon-eye"></i>
                                                </button>
                                            </div>`
                                    }
                                    else if(row.heatmapResponse && row.heatmapResponse.floorHeatmapStatus == "UNSATISFIED"){
                                        message += `<div style="flex: 1; width: auto; margin-left : 0em; margin-top: auto; margin-bottom : auto ">
                                                        <span class="kt-badge kt-badge--danger kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-danger">` +
                                                            i18n.t("troubleshooting_beaconsCheckingNotOK") +
                                                        `</span>
                                                    </div>
                                                    <div style="width: auto; margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("analytics_details") + `"type="button" data-floorName="` + row.floorName  + `"data-floorid="` + row.floorId + `"data-nbGateway="` + row.nbGateways + `"data-buildingName="` + row.buildingName + `"data-buildingId="`+ row.buildingId +
                                                            `" class="btn btn-label-brand btn-bold btn-circle btn-icon checkingStatutHeatmap ml-2" sytle = "height: 1rem; width: 1rem;">
                                                            <i class="flaticon-eye"></i>
                                                    </button>
                                                    </div>`
                                    }
                                    else if (row.heatmapResponse && row.heatmapResponse.floorHeatmapStatus == "NEARLY_SATISFIED") {
                                        message += `<div style="flex: 1; width: auto; margin-left : 0em; margin-top: auto; margin-bottom : auto ">
                                                        <span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">` +
                                                            i18n.t("troubleshooting_checkingNearlySatisfied") +
                                                        `</span>
                                                    </div>
                                                    <div style="width: auto; margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("analytics_details") + `"type="button" data-floorName="` + row.floorName  + `"data-floorid="` + row.floorId + `"data-nbGateway="` + row.nbGateways + `"data-buildingName="` + row.buildingName + `"data-buildingId="`+ row.buildingId +
                                                            `" class="btn btn-label-brand btn-bold btn-circle btn-icon checkingStatutHeatmap ml-2" sytle = "height: 1rem; width: 1rem;">
                                                            <i class="flaticon-eye"></i>
                                                    </button>
                                                    </div>`
                                    }
                                    else {
                                        message += `<div style="flex: 1; width: auto; margin-top: auto; margin-bottom : auto ">
                                                        -
                                                    </div>`
                                    }
                                    return message += `</div>`;
                                }
                            },
                            {
                                field: "gatewaysHealthOfFloorTable",
                                title: i18n.t("troubleshooting_gatewayCoverageStatus"),
                                textAlign: "center",
                                sortable: false,
                                autoHide: false,
                                width: 150,
                                template : function(row){
                                    self.nbGatewayDown = 0;
                                    self.nbGatewayUp = 0;
                                    let message = `<div style="
                                                    display: flex;
                                                    flex-direction: row;
                                                    width: auto
                                                ">
                                                    <div style="
                                                    flex: 1;
                                                    width: auto;
                                                    margin-left : 1.5em;
                                                    margin-top: auto;
                                                    margin-bottom : auto;
                                                ">`
                                    if(row.gatewaysHealthOfFloorTable && row.gatewaysHealthOfFloorTable.length > 0){
                                        for(let gateway of row.gatewaysHealthOfFloorTable){
                                            // Count only located gateways
                                            if (gateway.floor && gateway.building && gateway.location) {
                                                if (gateway.lastEmission && gateway.lastEmission != -1) {
                                                    self.nbGatewayUp +=1;
                                                } else {
                                                    self.nbGatewayDown +=1;
                                                }
                                            }
                                        }
                                    }
                                    if(!row.gatewaysHealthOfFloorTable || row.gatewaysHealthOfFloorTable.length == 0){
                                        message+= `<div style="flex: 1; width: auto; margin-top: auto; margin-bottom : auto ">
                                                        -
                                                    </div>`
                                    }
                                    if(self.nbGatewayDown > 0 ){
                                        message +=

                                            '<div><span class="kt-badge kt-badge--danger kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-danger">' + self.nbGatewayDown + " " +
                                            i18n.t("troubleshooting_gatewayStatusDown")+
                                            "</span></div>"
                                    }
                                    if(self.nbGatewayUp > 0 ){
                                        if(self.nbGatewayDown==0){
                                            message += `<div style="margin-right : 35px">`
                                        }
                                        else{
                                            message+= `<div>`
                                        }
                                        message +=
                                            '<span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' + self.nbGatewayUp + " " +
                                            i18n.t("troubleshooting_gatewayStatusUp") +
                                            "</span></div>"

                                    }
                                    if(self.nbGatewayDown > 0){
                                        message +=
                                            `</div>
                                            <div style="width: auto;margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("analytics_details") + `"type="button"` + `"data-floorName="` + row.floorName + `"data-floorid="` + row.floorId + `"data-nbGateway="` + row.nbGateways + `"data-buildingName="` + row.buildingName + `"data-floorName="` + row.floorName +
                                                `" class="btn btn-label-brand btn-bold btn-circle btn-icon checkingStatutCoverage sytle = "height: 1rem; width: 1rem;">
                                                    <i class="flaticon-eye"></i>
                                                </button>
                                            </div>`
                                    }
                                    return message += `</div>`;
                                }
                            },
                            {
                                field: "coverage",
                                title: i18n.t("troubleshooting_tagStatusCoverage"),
                                textAlign: "center",
                                sortable: false,
                                autoHide: false,
                                overflow : "visible",
                                width: 200,
                                template : function(row){
                                    let message = `<div style="
                                                    display: flex;
                                                    flex-direction: row;
                                                    width: auto
                                                ">`

                                    if(row.coverage == "UNSATISFIED"){
                                        const floorId = row.floorId;
                                        let floorConfigurationPercent = self.floorConfigurationStatus[floorId];
                                        if(floorConfigurationPercent < self.satisfiedPercent ){
                                            message+=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--danger kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-danger">` +
                                                    i18n.t("troubleshooting_checkingNotOK") +
                                                `</span>
                                            </div>
                                            <div style="width: auto; margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("analytics_details") + `"type="button" data-minLimit="` +
                                                row.minRadiationDensityByGateway + ` "data-maxLimit="` + row.maxRadiationDensityByGateway + `"data-floorName="` + row.floorName + `"data-radiationDensityByGateway="` + row.radiationDensityByGateway + `"data-floorCoverageDensity="` + row.floorCoverageDensity + `"data-floorid="` + row.floorId + `"data-nbGateway="` + row.nbGateways + `"data-buildingName="` + row.buildingName + `"data-floorId="` + row.floorId +
                                                    `" class="btn btn-label-brand btn-bold btn-circle btn-icon checkingIssue ml-2" sytle = "height: 1rem; width: 1rem;">
                                                        <i class="flaticon-eye"></i>
                                                </button>
                                            </div>`
                                        }
                                        else if (floorConfigurationPercent >= self.excellentPercent){
                                            message+=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">` +
                                                    i18n.t("troubleshooting_checkingNearlySatisfied") +
                                                `</span>
                                            </div>
                                            <div style="width: auto; margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("analytics_details") + `"type="button" data-minLimit="` +
                                                row.minRadiationDensityByGateway + ` "data-maxLimit="` + row.maxRadiationDensityByGateway + `"data-floorName="` + row.floorName + `"data-radiationDensityByGateway="` + row.radiationDensityByGateway + `"data-floorCoverageDensity="` + row.floorCoverageDensity + `"data-floorid="` + row.floorId + `"data-nbGateway="` + row.nbGateways + `"data-buildingName="` + row.buildingName + `"data-floorId="` + row.floorId +
                                                    `" class="btn btn-label-brand btn-bold btn-circle btn-icon checkingIssue ml-2" sytle = "height: 1rem; width: 1rem;">
                                                        <i class="flaticon-eye"></i>
                                                </button>
                                            </div>`
                                        }
                                        else if (floorConfigurationPercent >= self.satisfiedPercent){
                                            message+=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">` +
                                                    i18n.t("troubleshooting_checkingNearlySatisfied") +
                                                `</span>
                                            </div>
                                            <div style="width: auto; margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("analytics_details") + `"type="button" data-minLimit="` +
                                                row.minRadiationDensityByGateway + ` "data-maxLimit="` + row.maxRadiationDensityByGateway + `"data-floorName="` + row.floorName + `"data-radiationDensityByGateway="` + row.radiationDensityByGateway + `"data-floorCoverageDensity="` + row.floorCoverageDensity + `"data-floorid="` + row.floorId + `"data-nbGateway="` + row.nbGateways + `"data-buildingName="` + row.buildingName + `"data-floorId="` + row.floorId +
                                                    `" class="btn btn-label-brand btn-bold btn-circle btn-icon checkingIssue ml-2" sytle = "height: 1rem; width: 1rem;">
                                                        <i class="flaticon-eye"></i>
                                                </button>
                                            </div>`
                                        }
                                    }
                                    else if(row.coverage == "SATISFIED"){
                                        message +=
                                            '<div style="flex: 1; width: auto; margin-left : 0em; margin-top: auto; margin-bottom : auto "><span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">'+
                                                    i18n.t("troubleshooting_checkingExcellent") +
                                                    "</span></div>"
                                    }
                                    else if(row.coverage == "NO_LOCATED_GATEWAY"){
                                        message +=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">` +
                                                    i18n.t("troubleshooting_gatewayCheckingIssue") +
                                                `</span>
                                            </div>
                                            <div style="width: auto;margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_setLocation") + `"type="button" data-floorid="` + row.floorId +
                                                    `" class=" btn btn-label-brand btn-sm btn-icon btn-square editGatewayLocationButton ml-2" sytle = "height: 2rem; width: 2rem;">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                </button>
                                            </div>`
                                    }
                                    else if(row.coverage == "NO_INDOOR_AREA_CREATED"){
                                        message +=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">` +
                                                    i18n.t("troubleshooting_gatewayCheckingIndoorAreaIssue") +
                                                `</span>
                                            </div>
                                            <div style="width: auto;margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_create") + `"type="button" data-floorid="` + row.floorId +
                                                    `" class=" btn btn-label-brand btn-sm btn-icon btn-square editIndoorAreaButton ml-2" sytle = "height: 2rem; width: 2rem;">
                                                        <i class="fas fa-plus"></i>
                                                </button>
                                            </div>`
                                    }
                                    return message += `</div>`;
                                }
                            }
                        ]
                    };

                    // Now, we are ready to build the table
                    this.gatewayCheckingTable = $("#gatewayChecking_datatable").KTDatatable(options);
                    this.gatewayCheckingTable.sort("floorName", "asc");
                    // Once table is build, register click events on buttons inside the table
                    this.registerEventsOnGatewayCheckingTable();
                } else if(!this.gatewaysChecking || this.gatewaysChecking.length == 0) {
                    // There is no data => Show empty list icon+text
                    // No table process: Comment lines below because table destroy previously
                    this.isEmptyList = true;
                }
            } else {
                this.isEmptyList = true;
            }
        },

        // Function to sort beacons rssi measures table per distance from the nearest to the farthest
        sortRssiTable(lastRssiStrengthOfGatewaysOfFloor) {
            function compare( rssiStrength1, rssiStrength2 ) {
                if ( rssiStrength2.distanceBetweenActAndGtw === "-" && rssiStrength1.distanceBetweenActAndGtw !== "-" ) {
                    return -1;
                }
                else if (rssiStrength1.distanceBetweenActAndGtw === "-") {
                    return 1;
                }
                else if (parseInt(rssiStrength1.distanceBetweenActAndGtw) < parseInt(rssiStrength2.distanceBetweenActAndGtw)) {
                    return -1;
                }
                else if (parseInt(rssiStrength1.distanceBetweenActAndGtw) > parseInt(rssiStrength2.distanceBetweenActAndGtw)) {
                    return 1;
                }
                return 0;
            }
            return lastRssiStrengthOfGatewaysOfFloor.sort(compare);
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-gatewaycheckingmodal" : GatewaysCheckingModal,
        "app-gatewayscheckinghealthmodal" : GatewaysCheckingHealthModal,
        "app-heatmapgatewayanalysemodal" : HeatmapGatewayAnalyseModal
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>