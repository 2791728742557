<template>
    <div
        class="modal fade show"
        id="gatewayscheckingModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modalHelpPlacement" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="kt-portlet__head-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                            class="kt-svg-icon"
                        >
                            <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                />
                                <path
                                    d="M19.366142,13.9305937 L17.2619853,15.6656848 C15.9733542,14.1029531 14.0626842,13.1818182 11.9984835,13.1818182 C9.94104045,13.1818182 8.03600715,14.0968752 6.74725784,15.6508398 L4.64798148,13.9098472 C6.44949126,11.7375997 9.12064835,10.4545455 11.9984835,10.4545455 C14.8857906,10.4545455 17.5648042,11.7460992 19.366142,13.9305937 Z M23.5459782,10.4257575 L21.4473503,12.1675316 C19.1284914,9.37358605 15.6994058,7.72727273 11.9984835,7.72727273 C8.30267753,7.72727273 4.87785708,9.36900008 2.55893241,12.1563207 L0.462362714,10.4120696 C3.29407133,7.00838857 7.48378666,5 11.9984835,5 C16.519438,5 20.7143528,7.01399004 23.5459782,10.4257575 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                />
                                <path
                                    d="M15.1189503,17.3544974 L13.0392442,19.1188213 L11.9619232,20 L10.9331836,19.1485815 L8.80489611,17.4431757 C9.57552634,16.4814558 10.741377,15.9090909 11.9984835,15.9090909 C13.215079,15.9090909 14.347452,16.4450896 15.1189503,17.3544974 Z"
                                    id="Oval-21"
                                    fill="#000000"
                                />
                            </g>
                        </svg>
                    </span>
                    <h3 class="modal-title ml-2"> {{ $t("troubleshooting_warningModalTitle") }}</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="kt-iconbox kt-iconbox--info">
                        <div class="kt-iconbox__body">
                            <div class="row">
                            </div>
                            <div
                                class="row troubleshooting_warningMessage">{{ $t("troubleshooting_warningModalSubTitle", {floorName: floorName, buildingName: buildingName}) }}
                                <!-- begin info -->
                            </div>
                            <!--begin: Datatable -->
                            <div class="troubleshooting_warningMessage">
                                <div class="kt-infobox__body">
                                    <div
                                        class="accordion accordion-light  accordion-svg-icon"
                                        id="accordionExample2"
                                    >
                                        <div v-if = "gatewaysCheckingResponse.includes('NB_GATEWAY_NOT_SUFFICIENT')" class="card">
                                            <div class="card-header" id="headingTwo2">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseTwo2"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo2"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $t("troubleshooting_gatewayNumberInsufficient") }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseTwo2"
                                                class="collapse"
                                                aria-labelledby="headingTwo2"
                                                data-parent="#accordionExample2"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    {{ $t("troubleshooting_warningNbGateways", {floorName: floorName, nbGateways: nbGateways}) }}
                                                    <br>
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGatewayLocation">
                                                                {{ $t("troubleshooting_seeGatewayLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "gatewaysCheckingResponse.includes('UNSATISFIED_GATEWAY_DENSITY')" class="card" >
                                            <div class="card-header" id="headingTwo1">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseTwo1"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo1"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $t("troubleshooting_gatewayCheckingDensityNotOK") }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseTwo1"
                                                class="collapse"
                                                aria-labelledby="headingTwo1"
                                                data-parent="#accordionExample2"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    {{ $t("troubleshooting_warningCoverage", {floorName: floorName, radiationDensityByGateway: Math.round(radiationDensityByGateway), minLimit: minLimit, maxLimit: maxLimit}) }}
                                                    <br>
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGatewayLocation">
                                                                {{ $t("troubleshooting_seeGatewayLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "gatewaysCheckingResponse.includes('GATEWAY_NOT_LOCATED_INSIDE_ONE_INDOOR_AREA')" class="card">

                                            <div class="card-header" id="headingTwo3">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseTwo3"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo3"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $tc("troubleshooting_notLocatedInsideOneIndoorArea", gatewaysNotWellLocated.length, gatewaysNotWellLocated.length) }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseTwo3"
                                                class="collapse"
                                                aria-labelledby="headingTwo3"
                                                data-parent="#accordionExample2"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningGatewaysLocalisation", {floorName: floorName, nbGateways: nbGateways}) }}</div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <table id="gatewayLocation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 60% ; margin: auto">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("troubleshooting_gatewayNameLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_gatewayMacAddressLabel") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="gateway in gatewaysNotWellLocated" :key="gateway.id">
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGatewayName(gateway.id)">
                                                                                {{ gateway.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {{ gateway.bleMacAddress}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGatewayLocation">
                                                                {{ $t("troubleshooting_seeGatewayLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "gatewaysCheckingResponse.includes('UNSATISFIED_DISTANCE_BETWEEN_GATEWAYS_AND_WALLS')" class="card">
                                            <div class="card-header" id="headingTwo7">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseTwo7"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo7"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $tc("troubleshooting_warningGatewaysWallsDistance", gatewaysWithWrongWallDistance.length, gatewaysWithWrongWallDistance.length) }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseTwo7"
                                                class="collapse"
                                                aria-labelledby="headingTwo7"
                                                data-parent="#accordionExample2"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $tc("troubleshooting_warningGatewaysWallsDistanceMessage", nbGateways, { nbGateways: nbGateways, floorName: floorName}) }}</div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <table id="gatewayLocation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 100% ; margin: auto; table-layout: fixed;">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("gtw_bleGateway") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("wall_wallNameLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_distance") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(gateway, index) in gatewaysWithWrongWallDistance" :key="index">
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGatewayName(gateway.gateway.id)" style = "margin-bottom: auto; margin-top: auto;">
                                                                                {{ gateway.gateway.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickWallName(gateway.wall.id)" style = "margin-bottom: auto; margin-top: auto;">
                                                                                {{ gateway.wall.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style = "margin-bottom: auto; margin-top: auto;">
                                                                        {{ gateway.distance + " m "}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGatewayLocation">
                                                                {{ $t("troubleshooting_seeGatewayLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "gatewaysCheckingResponse.includes('UNSATISFIED_DISTANCE_BETWEEN_GATEWAYS')" class="card">
                                            <div class="card-header" id="headingTwo5">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseTwo5"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo5"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $tc("troubleshooting_warningGatewaysDistance", gatewaysWithWrongDistance.length, gatewaysWithWrongDistance.length) }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseTwo5"
                                                class="collapse"
                                                aria-labelledby="headingTwo5"
                                                data-parent="#accordionExample2"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningGatewaysDistanceMessage", {floorName: floorName, nbGateways: nbGateways}) }}</div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <table id="gatewayLocation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 80% ; margin: auto; table-layout: fixed;">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("troubleshooting_gatewayNameLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_gatewayMacAddressLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_distance") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(gateway, index) in gatewaysWithWrongDistance" :key="index">
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGatewayName(gateway.gateway1.id)">
                                                                                {{ gateway.gateway1.name}}
                                                                            </a>
                                                                            <br>
                                                                            <a v-if="gateway.gateway2.name!='-'" class="kt-link kt-user-card-v2__name" @click="onClickGatewayName(gateway.gateway2.id)">
                                                                                {{ gateway.gateway2.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {{ gateway.gateway1.bleMacAddress}}
                                                                    <br>
                                                                    <div v-if="gateway.gateway2.name!='-'">
                                                                        {{ gateway.gateway2.bleMacAddress}}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div v-if = "gateway.distance == '!' ">
                                                                        <a href = "#" class="kt-link" @click="printMessage" style="cursor:pointer ;">
                                                                            {{" > 15 m * "}}
                                                                        </a>
                                                                    </div>
                                                                    <div v-else style = "margin-bottom: 10px; margin-top: 10px;">
                                                                        {{gateway.distance + " m "}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <br>
                                                    <div v-if = "displayGatewayFarAwayExplabations " class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningGatewaysDistanceMessage2") }}</div>
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGatewayLocation">
                                                                {{ $t("troubleshooting_seeGatewayLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "gatewaysCheckingResponse.includes('UNSATISFIED_FLOOR_ELEVATION')" class="card">
                                            <div class="card-header" id="headingTwo4">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseTwo4"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo4"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $t("troubleshooting_titlewarningFloorElevation") }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseTwo4"
                                                class="collapse"
                                                aria-labelledby="headingTwo4"
                                                data-parent="#accordionExample2"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningFloorElevation", {floorName: floorName, nbGateways: nbGateways}) }}
                                                        <!-- begin info -->
                                                    </div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <table id="gatewaysFloorElevation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 60% ; margin: auto">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("troubleshooting_height") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_gatewayNumber") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(beacon, index) in infoGtwFloorElevation" :key="index">
                                                                <td>{{ beacon.height + " m" }}</td>
                                                                <td>
                                                                    {{ beacon.nbGateways }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGatewayLocation">
                                                                {{ $t("troubleshooting_seeGatewayLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1 flex-shrink-0">
                        <div class="progress mt-2 mb-2" style=" height : auto">
                            <div v-bind:class="classProgressBar" role="progressbar" v-bind:style="styleProgressBar" aria-valuenow= "floorConfigurationPercent" aria-valuemin="0" aria-valuemax="100">
                                {{floorConfigurationPercent}}%
                            </div>
                        </div>
                    </div>
                    <span class="font-weight-bold" style ="color: rgb(80, 79, 79);">{{$tc("troubleshooting_progressBarExplanations",gatewaysCheckingResponse.length,  {nbIssueResolve: gatewaysCheckingResponse.length})}}</span>
                </div>
                <div class="modal-footer">
                    <button
                        id="aboutOkButton"
                        type="button"
                        class="btn btn-brand"
                        data-dismiss="modal"
                    >{{ $t("common_okThanks") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {

    data (){
        return {
            infoGtwFloorElevation :[],
            displayGatewayFarAwayExplabations : false,
            styleProgressBar: { width: "0%", color: "black", "margin-left": "10px"},
            classProgressBar : "progress-bar bg-danger",
        };
    },

    props: {
        minLimit : {
            type: String
        },
        maxLimit : {
            type : String
        },
        floorName : {
            type : String,
        },
        radiationDensityByGateway : {
            type : String
        },
        gatewaysCheckingResponse : {
            type : Array
        },
        nbGateways : {
            type : String
        },
        gatewaysNotWellLocated : {
            type : Array
        },
        siteId : {
            type : String
        },
        gtwFloorElevation : {
            type : Object
        },
        buildingName : {
            type : String
        },
        gatewaysWithWrongDistance : {
            type : Array
        },
        floorId : {
            type : String
        },
        satifiedPercent : {
            type : Number
        },
        excellentPercent : {
            type : Number
        },
        floorConfigurationPercent : {
            type : Number
        },
        gatewaysWithWrongWallDistance : {
            type : Array
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([ "user"]),

    },
    mounted: function(){
        $("#gatewayscheckingModal").on('hidden.bs.modal', function() {
            const findOpenElement = $('.collapse.show');
            if (findOpenElement) {
                findOpenElement.collapse('hide');
            }
        })
    },
    watch: {
        gatewaysCheckingResponse (){
            console.log("Component(gatewayscheckingmodal)::watch(gtwFloorElevation) called with : ",this.gatewaysCheckingResponse);
            this.progresseBarAction();
        },

        // take back the keys and values from the object gtwFloorElevation
        gtwFloorElevation(){
            console.log("Component(gatewayscheckingmodal)::watch(gtwFloorElevation) called with : ", this.gtwFloorElevation);
            this.infoGtwFloorElevation = [];
            const keys = Object.keys(this.gtwFloorElevation);
            const values = Object.values(this.gtwFloorElevation);
            for(let i = 0 ; i < values.length ; i++){

                const dataStored = {
                    nbGateways : values[i],
                    height : keys[i]
                };

                this.infoGtwFloorElevation.push(dataStored);
            }
        }
    },
    methods: {

        // function Onclick event to go to edit gateway location vue and highlight the right gateway
        onClickGatewayName(gatewayId) {

            if((this.gatewaysNotWellLocated && this.gatewaysNotWellLocated.length > 0) || (this.gatewaysWithWrongDistance && this.gatewaysWithWrongDistance.length > 0) || (this.gatewaysWithWrongWallDistance && this.gatewaysWithWrongWallDistance.length > 0)){

                this.$router.push({
                    name: "gatewaysLocation",
                    params: { siteId: this.siteId, highlight: gatewayId }
                });
                $("#gatewayscheckingModal").modal("hide");
            }
        },

        // function Onclick event to go to wall location vue and search the right wall in the list
        onClickWallName(wallId) {
            if(this.gatewaysWithWrongWallDistance && this.gatewaysWithWrongWallDistance.length > 0){

                const keyStrorage = this.siteId + "_wallsDefinition_lastDisplayedFloorId";
                localStorage.setItem(keyStrorage, this.floorId);

                this.$router.push({
                    name : "walls",
                    params : {siteId : this.siteId, wallId : wallId }
                })
                $("#gatewayscheckingModal").modal("hide");
            }

        },

        // function Onclick event to go to edit gateway location vue
        onClickGatewayLocation() {

            const keyStrorage = this.siteId + "_gatewaysLocation_lastDisplayedFloorId";
            localStorage.setItem(keyStrorage, this.floorId)
            this.$router.push({
                name: "gatewaysLocation",
                params: { siteId: this.siteId } });
            $("#gatewayscheckingModal").modal("hide");
        },

        // Toggle on message showing
        printMessage() {
            this.displayGatewayFarAwayExplabations = !this.displayGatewayFarAwayExplabations;
        },

        //return the progress bar value for a given number of issue
        progresseBarAction() {
            if (this.floorConfigurationPercent == 0) {
                this.styleProgressBar = { width: this.floorConfigurationPercent + "%", color: "red", "font-weight": "600" };
                this.classProgressBar = "progress-bar bg-danger"
            } else if (this.floorConfigurationPercent > 0 && this.floorConfigurationPercent < 40) {
                this.styleProgressBar = { width: this.floorConfigurationPercent + "%", color: "white", "font-weight": "600" };
                this.classProgressBar = "progress-bar bg-danger"
            } else if(this.floorConfigurationPercent >= 40 && this.floorConfigurationPercent < this.satifiedPercent){
                this.styleProgressBar = { width: this.floorConfigurationPercent + "%", color: "white", "font-weight": "600" };
                this.classProgressBar = "progress-bar bg-warning"
            } else {
                this.styleProgressBar = { width: this.floorConfigurationPercent + "%", color: "white", "font-weight": "600" };
                this.classProgressBar = "progress-bar bg-success"
            }
        }
    }
};
</script>

<style scoped>
.troubleshooting_warningMessage{
    margin: 2px 1em 0 auto;
    color: rgb(80, 79, 79);
    font-weight: 100;
    text-align: start;
}
</style>