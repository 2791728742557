<template>
    <div
        class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile"
    >
        <div
            class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm"
        >
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        class="kt-svg-icon"
                    >
                        <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <rect
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                            />
                            <path
                                d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                                opacity="0.3"
                            />
                            <path
                                d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                            />
                        </g>
                    </svg>
                </span>
                <h3
                    class="kt-portlet__head-title"
                >{{ $t("troubleshooting_beaconsChecking") }}</h3>
            </div>
            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-wrapper">
                    <div class="kt-portlet__head-toolbar">
                        <div class="row align-items-center">
                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                <div class="kt-input-icon kt-input-icon--left">
                                    <input type="text" :placeholder="$t('common_searchbyB&F')" @keyup="onSearch" v-model="searchedValue" id="act_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                        <span><i class="la la-search"></i></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin emptyList component-->
        <app-emptylist targetObjectName="BEACONSHEALTH_LIST" mainTextKey="troubleshooting_emptyBeaconsChecking" subTextKey="troubleshooting_emptyBeaconsCheckingSubTitle" imgName="empty_autocalibrationtag.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
        <!--end emptyList component-->
        <div class="kt-portlet__body kt-portlet__body--fit">
            <!--begin: Datatable -->
            <div class="kt-datatable" id="beaconsChecking_datatable"></div>
            <app-beaconscheckingmodal :autotagsWithWrongWallDistance="autotagsWithWrongWallDistance" :floorConfigurationPercent="floorConfigurationPercent" :excellentPercent="excellentPercent" :satifiedPercent="satisfiedPercent" :minOfAutotagForOneGateway="minOfAutotagForOneGateway" :numberAutotagPerGtwRequire="numberAutotagPerGtwRequire" :floorName="floorName" :buildingName="buildingName" :autoTagFloorElevationResponse="autoTagFloorElevationResponse" :nbGatewaysLocated="nbGatewaysLocated" :nbAutotagLocated="nbAutotagLocated" :autoCalibrationTagsCheckingResponse="autoCalibrationTagsCheckingResponse" :numberAutotagRequire="numberAutotagRequire" :autoCalibrationTagsNotWellLocated="autoCalibrationTagsNotWellLocated" :floorCoverageDensity="floorCoverageDensity" :siteId="siteId" :geofenceWithNoAutoTagTable="geofenceWithNoAutoTagTable" :floorId="floorId" :autocalibrationTagsWithWrongDistance="autocalibrationTagsWithWrongDistance" :autocalibrationTagsWithWrongGtwDistance="autocalibrationTagsWithWrongGtwDistance"></app-beaconscheckingmodal>
            <app-beaconcheckinghealthmodal :autocalibrationTagsHealthOfFloorTable="autocalibrationTagsHealthOfFloorTable" :floorName="floorName" :buildingName="buildingName" :nbGatewaysLocated="nbGatewaysLocated" :nbAutotagLocated="nbAutotagLocated" :siteId="siteId" :floorId="floorId"></app-beaconcheckinghealthmodal>
            <!--end: Datatable -->
        </div>
    </div>
</template>

<script>
import {mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import BeaconsCheckingModal from "../modals/beaconscheckingmodal.vue";
import BeaconCheckingHealthModal from "../modals/beaconscheckinghealthmodal.vue";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            isEmptyList: true,
            beaconsCheckingTable: null,
            searchedValue : "",
            minOfAutotagForOneGateway : "",
            numberAutotagRequire : "",
            floorName : "",
            nbOfAutotagPerGateway : "",
            nbAutotagLocated : "",
            nbGatewaysLocated : "",
            autoTagFloorElevationResponse : {},
            actionType : "",
            autoCalibrationTagsCheckingResponse : [],
            autoCalibrationTagsNotWellLocated : [],
            geofenceWithNoAutoTagTable : {},
            floorCoverageDensity  : "",
            buildingName : "",
            numberAutotagPerGtwRequire : "",
            floorId : "",
            autocalibrationTagsWithWrongDistance : [],
            autocalibrationTagsWithWrongGtwDistance : [],
            autotagsWithWrongWallDistance : [],
            nbAutotagBad : 0,
            nbAutotagGood : 0,
            nbAutotagExcelllent : 0,
            nbAutotagOutOfCouverage : 0,
            autocalibrationTagsHealthOfFloorTable : [],
            refOfIssuesPercent: {
                INSUFFICIENT_AUTOTAG_NUMBER_FOR_AREA : 17,
                INSUFFICIENT_AUTOTAG_NUMBER_FOR_GATEWAY_NUMBER : 5,
                AUTOTAG_NOT_LOCATED_INSIDE_INDOORAREA : 17,
                AUTOTAG_NOT_LOCATED_INSIDE_ONE_GEOFENCE : 17,
                AUTOTAG_AT_DIFFERENT_HEIGHT : 5,
                UNSATISFIED_DISTANCE_BETWEEN_AUTOTAG : 5,
                UNSATISFIED_DISTANCE_BETWEEN_AUTOTAG_AND_GATEWAY : 17,
                UNSATISFIED_DISTANCE_BETWEEN_AUTOTAG_AND_WALL : 17
            },
            satisfiedPercent: 85,
            excellentPercent: 90,
            floorConfigurationStatus : {},
            floorConfigurationPercent : 0

        };
    },
    created: function() {
        console.log("Component(beaconschecking)::created() - called");
    },
    mounted: function() {
        console.log("Component(beaconschecking)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(beaconschecking)::destroyed() - called");
    },
    props: {
        beaconsChecking : {
            type: Array,
            required: true
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".

        beaconsChecking() {
            console.log("Component(beaconschecking)::watch(beaconschecking) called with : ", this.beaconsChecking);
            for(let data of this.beaconsChecking){
                if(data && data.hasOwnProperty('autoCalibrationTagsCheckingResponse')){
                    let floorId = data.floorId;
                    let floorConfigurationPercent = this.onChangeConfigurationStatus(data);
                    this.floorConfigurationStatus[floorId] = floorConfigurationPercent;
                }
            }
            this.initKtDataTable(this.beaconsChecking);
        },

        user: function(user) {
            if (user) {
                console.log("Component(beaconschecking)::watch(user) called with : ", user);
                this.initKtDataTable(this.beaconsChecking);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --teAnoth
        ...mapGetters(["user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---

        // Function called when user wants to search a specific value
        onSearch() {
            if (this.beaconsCheckingTable) {
                const searchedValueUpperCase = this.searchedValue.toUpperCase();
                // 1. Search in "floorName" field of beacons checking table
                const dataFoundByFloorName = _.filter(this.beaconsChecking, beacons => beacons && beacons.floorName && beacons.floorName.toUpperCase().includes(searchedValueUpperCase));
                // 2. Search in "buildingName" field of beacons checking table
                const dataFoundByBuildingName = _.filter(this.beaconsChecking, beacons => beacons && beacons.buildingName && beacons.buildingName.toUpperCase().includes(searchedValueUpperCase));

                const dataFound = _.unionBy(dataFoundByFloorName, dataFoundByBuildingName);
                if (dataFound && dataFound.length > 0) {
                    // Reload datatable with only rows which contain searchedValue
                    this.initKtDataTable(dataFound);
                } else {
                    // No searchedValue found => Display empty datatable
                    this.beaconsCheckingTable.search(this.searchedValue, "unknown");
                }
            }
        },

        // Function used to adapt the configuration status for the level of the issue that you have on your site deployment
        onChangeConfigurationStatus(data){
            let percentOfIssueResolved = 0;
            // look if the gateway checking response has the key issue and adapt in function the configuration status
            for (let [key, value] of Object.entries(this.refOfIssuesPercent)) {
                if (!data.autoCalibrationTagsCheckingResponse.includes(key)) {
                    percentOfIssueResolved += value;
                }
            }
            return percentOfIssueResolved;
        },

        // Go to the Indoor Area edit page
        onCreateIndoorArea: function() {
            this.$router.push({
                name: "indoorAreas",
                params: { siteId: this.siteId }
            });
        },
        // Go to the Gateway Location edit page
        onEditGatewayLocation: function() {
            this.$router.push({ name: "gatewaysLocation", params: { siteId: this.siteId } });
        },
        // Go to the Beacon Location edit page
        onEditBeaconLocation : function() {
            this.$router.push({name : "autocalibrationtagsLocation", params: { siteId: this.siteId }});
        },

        registerEventsOnBeaconsCheckingTable() {
            var self = this;
            $("#beaconsChecking_datatable").on("kt-datatable--on-layout-updated", () => {
                $(".checkingIssues").off().on("click", function() {
                    console.log("Component(beaconschecking)::registerEventsOnGatewayCheckingTable(checkingIssues) clicked. ");
                    self.minOfAutotagForOneGateway = $(this).attr("data-minOfAutotagForOneGateway");
                    self.numberAutotagRequire = $(this).attr("data-numberAutotagRequire");
                    self.floorName = $(this).attr("data-floorName");
                    self.buildingName = $(this).attr("data-buildingName");
                    self.numberAutotagPerGtwRequire = $(this).attr("data-numberAutotagPerGtwRequire");
                    self.nbGatewaysLocated = $(this).attr("data-nbGatewaysLocated");
                    self.nbAutotagLocated = $(this).attr("data-nbAutotagLocated");
                    self.floorCoverageDensity = $(this).attr("data-floorCoverageDensity");
                    self.floorId = $(this).attr("data-floorid");
                    self.floorConfigurationPercent = self.floorConfigurationStatus[self.floorId];

                    //take back all the table or object that we need
                    for (let beacon of self.beaconsChecking) {
                        if (beacon.floorId === self.floorId && beacon.hasOwnProperty('autoCalibrationTagsNotWellLocated')){
                            self.autoCalibrationTagsNotWellLocated = beacon.autoCalibrationTagsNotWellLocated;
                        }
                        if (beacon.floorId === self.floorId && beacon.hasOwnProperty('autoTagFloorElevationResponse')) {
                            self.autoTagFloorElevationResponse = beacon.autoTagFloorElevationResponse;
                        }
                        if (beacon.floorId === self.floorId && beacon.hasOwnProperty('autoCalibrationTagsCheckingResponse')) {
                            self.autoCalibrationTagsCheckingResponse = beacon.autoCalibrationTagsCheckingResponse;
                        }
                        if (beacon.floorId === self.floorId && beacon.hasOwnProperty('geofenceWithNoAutoTagTable')){
                            self.geofenceWithNoAutoTagTable = beacon.geofenceWithNoAutoTagTable;
                        }
                        if (beacon.floorId === self.floorId && beacon.hasOwnProperty('autocalibrationTagsWithWrongDistance')){
                            self.autocalibrationTagsWithWrongDistance = beacon.autocalibrationTagsWithWrongDistance;
                        }
                        if (beacon.floorId === self.floorId && beacon.hasOwnProperty('autocalibrationTagsWithWrongGtwDistance')){
                            self.autocalibrationTagsWithWrongGtwDistance = beacon.autocalibrationTagsWithWrongGtwDistance;
                        }
                        if (beacon.floorId === self.floorId && beacon.hasOwnProperty('autotagsWithWrongWallDistance')){
                            self.autotagsWithWrongWallDistance = beacon.autotagsWithWrongWallDistance;
                        }

                    }
                    // Hide and destroy element’s tooltip
                    $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                    //show the two limits of coverag
                    $("#beaconsCheckingModal").modal("show");
                });

                $(".checkingBeaconHealthStatutCoverage").off().on("click", function() {
                    console.log("Component(beaconschecking)::registerEventsOnGatewayCheckingTable(checkingStatutCoverage) clicked. ");
                    self.floorName = $(this).attr("data-floorName");
                    self.buildingName = $(this).attr("data-buildingName");
                    self.nbGatewaysLocated = $(this).attr("data-nbGatewaysLocated");
                    self.nbAutotagLocated = $(this).attr("data-nbAutotagLocated");
                    self.floorId = $(this).attr("data-floorid");

                    //take back all the table or object that we need
                    for (let beacon of self.beaconsChecking) {
                        if (beacon.floorId === self.floorId && beacon.hasOwnProperty('autocalibrationTagsHealthOfFloorTable')){
                            self.autocalibrationTagsHealthOfFloorTable = beacon.autocalibrationTagsHealthOfFloorTable.filter((obj) => obj.floor && obj.building && obj.location);
                        }

                    }
                    // Hide and destroy element’s tooltip
                    $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                    //show the two limits of coverag
                    $("#beaconscheckinghealthModal").modal("show");
                });

                $(".editIndoorAreaButton")
                    .off()
                    .on("click", function() {
                        console.log("Component(beaconschecking)::registerEventsOnGatewayCheckingTable(editIndoorAreaButton) clicked. ");
                        const floorId = $(this).attr("data-floorid");
                        const keyStrorage = self.siteId + "_indoorAreasDefinition_lastDisplayedFloorId";
                        localStorage.setItem(keyStrorage, floorId)
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit Indoor Area vue
                        self.onCreateIndoorArea();
                    });

                $(".editGatewayLocationButton")
                    .off()
                    .on("click", function() {
                        console.log("Component(beaconschecking)::registerEventsOnGatewayCheckingTable(editGatewayLocationButton) clicked.");
                        const floorId = $(this).attr("data-floorid");
                        const keyStrorage = self.siteId + "_gatewaysLocation_lastDisplayedFloorId";
                        localStorage.setItem(keyStrorage, floorId)
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit gateway location vue
                        self.onEditGatewayLocation();
                    });
                $(".editBeaconLocationButton")
                    .off()
                    .on("click", function() {
                        console.log("Component(beaconschecking)::registerEventsOnGatewayCheckingTable(editGatewayLocationButton) clicked.");
                        const floorId = $(this).attr("data-floorid");
                        const keyStrorage = self.siteId + "_autocalibrationtagsLocation_lastDisplayedFloorId";
                        localStorage.setItem(keyStrorage, floorId)
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to Beacon location vue
                        self.onEditBeaconLocation();
                    });
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");
                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },

        initKtDataTable(dataSource) {
            var self = this;

            if (this.beaconsChecking != null) {

                // If beacons table has been already build, destroy the table before creating a fresh instance !
                if (this.beaconsCheckingTable) {
                    $("#beaconsChecking_datatable").KTDatatable().destroy();
                }

                // According to source list length, show datatable or empty list icon+text
                if (this.beaconsChecking.length > 0) {
                    // There is data => Hide empty list icon+text
                    this.isEmptyList = false;

                    const options = {
                        data: {
                            type: "local",
                            source: commonVueHelper.preventHtmlInjection(dataSource),
                            pageSize: 5,
                            saveState: {
                                webstorage: false
                            }
                        },
                        layout: commonVueHelper.getLayoutOptions(),
                        translate: commonVueHelper.getTranslateOptions(),
                        toolbar: commonVueHelper.getToolbarOptions(),
                        sortable: !0,
                        pagination: !0,
                        columns: [
                            {
                                field: "floorName",
                                title: i18n.t("gtw_bleGatewayFloorNameLabel"),
                                textAlign: "center",
                                overflow: "visible",
                                autoHide: false,
                                //width: 150
                            },
                            {
                                field: "buildingName",
                                title: i18n.t("gtw_bleGatewayBuildingNameLabel"),
                                textAlign: "center",
                                overflow: "visible",
                                //width: 150
                            },
                            {
                                field: "floorCoverageDensity",
                                title: i18n.t("troubleshooting_floorCoverage"),
                                textAlign: "center",
                                //width: 150,
                                template : function(row) {
                                    if(row.floorCoverageDensity && row.floorCoverageDensity!=0){
                                        return row.floorCoverageDensity + " m²";
                                    }else{
                                        return "-";
                                    }
                                }
                            },
                            {
                                field: "nbAutotagLocated",
                                title: i18n.t("troubleshooting_beaconsNumber"),
                                textAlign: "center",
                                sortable: false,
                                width: 150,
                                template : function(row){

                                    if(row.nbAutotagLocated && row.nbAutotagLocated != 0){
                                        return row.nbAutotagLocated;
                                    }
                                    else{
                                        return "-";
                                    }
                                }
                            },
                            {
                                field: "nbGatewaysLocated",
                                title: i18n.t("troubleshooting_gatewayNumber"),
                                textAlign: "center",
                                sortable: false,
                                width: 150,
                                template : function(row){

                                    if(row.nbGatewaysLocated && row.nbGatewaysLocated != 0){
                                        return row.nbGatewaysLocated;
                                    }
                                    else{
                                        return "-";
                                    }
                                }
                            },
                            {
                                field: "autocalibrationTagsHealthOfFloorTable",
                                title: i18n.t("troubleshoooting_beaconsStatus"),
                                textAlign: "center",
                                sortable: false,
                                width: 230,
                                autoHide: false,
                                overflow : "visible",
                                template : function(row){
                                    self.nbAutotagBad = 0;
                                    self.nbAutotagExcelllent = 0;
                                    self.nbAutotagGood = 0;
                                    self.nbAutotagOutOfCouverage = 0;
                                    let message = `<div style="
                                                    display: flex;
                                                    flex-direction: row;
                                                    width: auto
                                                ">
                                                    <div style="
                                                    flex: 1;
                                                    width: auto;
                                                    margin-left : 1.5em;
                                                    margin-top: auto;
                                                    margin-bottom : auto;
                                                ">`
                                    if(row.autocalibrationTagsHealthOfFloorTable && row.autocalibrationTagsHealthOfFloorTable.length != 0){
                                        for(let autotag of row.autocalibrationTagsHealthOfFloorTable){
                                            // Count only when autotag is located
                                            if (autotag.floor && autotag.building && autotag.location) {
                                                if (autotag.coverage.length == 0) {
                                                    self.nbAutotagOutOfCouverage +=1;
                                                }
                                                else if (autotag.coverage.length == 1 || autotag.coverage.length == 2) {
                                                    self.nbAutotagBad +=1;
                                                } else if (autotag.coverage.length == 3) {
                                                    self.nbAutotagGood +=1;
                                                } else {
                                                    self.nbAutotagExcelllent +=1;
                                                }
                                            }
                                        }
                                    }
                                    if(!row.autocalibrationTagsHealthOfFloorTable || row.autocalibrationTagsHealthOfFloorTable.length == 0){
                                        message+= `<div style="flex: 1; width: auto; margin-top: auto; margin-bottom : auto ">
                                                        -
                                                    </div>`
                                    }
                                    if(self.nbAutotagOutOfCouverage > 0){
                                        message += '<div><span class="kt-badge kt-badge--danger kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-danger">' + self.nbAutotagOutOfCouverage + " " +
                                                    i18n.t("troubleshooting_tagStatusNone") +
                                                    "</span></div>"
                                    }
                                    if(self.nbAutotagBad > 0 ){
                                        message += '<div><span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">' + self.nbAutotagBad + " " +
                                                    i18n.t("troubleshooting_tagStatusBad") +
                                                    "</span></div>"
                                    }
                                    if(self.nbAutotagGood > 0){
                                        message +=  '<div><span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' + self.nbAutotagGood + " " +
                                                    i18n.t("troubleshooting_tagStatusGood") +
                                                    "</span></div>"
                                    }
                                    if(self.nbAutotagExcelllent > 0){
                                        message += '<div><span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' + self.nbAutotagExcelllent + " " +
                                                    i18n.t("troubleshooting_tagStatusExcellent") +
                                                    "</span></div>"
                                    }
                                    if(self.nbAutotagBad > 0 || self.nbAutotagOutOfCouverage > 0){
                                        message += `
                                            </div>
                                            <div style="width: auto;margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top"  data-original-title="` + i18n.t("analytics_details") + `"type="button"` + `"data-floorName="` + row.floorName + `"data-floorid="` + row.floorId + `"data-nbGatewaysLocated="` + row.nbGatewaysLocated + `"data-buildingName="` + row.buildingName + `"data-floorName="` + row.floorName + `"data-nbAutotagLocated="` + row.nbAutotagLocated +
                                                `" class="btn btn-label-brand btn-bold btn-circle btn-icon checkingBeaconHealthStatutCoverage sytle = "height: 1rem; width: 1rem;">
                                                    <i class="flaticon-eye"></i>
                                                </button>
                                            </div>`
                                    }
                                    return message +=  `</div>`;

                                }
                            },
                            {
                                field: "coverage",
                                title: i18n.t("troubleshooting_tagStatusCoverage"),
                                textAlign: "center",
                                sortable: false,
                                autoHide: false,
                                overflow : "visible",
                                width: 200,
                                template : function(row){
                                    let message = `<div style="
                                                    display: flex;
                                                    flex-direction: row;
                                                    width: auto
                                                ">`
                                    if(row.coverage == "UNSATISFIED"){
                                        const floorId = row.floorId;
                                        let floorConfigurationPercent = self.floorConfigurationStatus[floorId];
                                        if(floorConfigurationPercent < self.satisfiedPercent){
                                            message +=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--danger kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-danger">` +
                                                    i18n.t("troubleshooting_checkingNotOK") +
                                                `</span>
                                            </div>
                                            <div style="width: auto; margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("analytics_details") + `"type="button" data-minOfAutotagForOneGateway="` +
                                                row.minOfAutotagForOneGateway + `"data-floorName="` + row.floorName + `"data-numberAutotagRequire="` + row.numberAutotagRequire + `"data-floorId="` + row.floorId + `"data-numberAutotagPerGtwRequire="` + row.numberAutotagPerGtwRequire + `"data-nbGatewaysLocated="` + row.nbGatewaysLocated + `"data-nbAutotagLocated="` + row.nbAutotagLocated + `"data-floorCoverageDensity="` + row.floorCoverageDensity + `"data-buildingName="` + row.buildingName +
                                                `" class="btn btn-label-brand btn-bold btn-icon btn-circle checkingIssues ml-2" sytle = "height: 1rem; width: 1rem;">
                                                    <i class="flaticon-eye"></i>
                                                </button>
                                            </div>`
                                        }
                                        else if(floorConfigurationPercent >= self.excellentPercent){
                                            message +=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">` +
                                                    i18n.t("troubleshooting_checkingNearlySatisfied") +
                                                `</span>
                                            </div>
                                            <div style="width: auto; margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("analytics_details") + `"type="button" data-minOfAutotagForOneGateway="` +
                                                row.minOfAutotagForOneGateway + `"data-floorName="` + row.floorName + `"data-numberAutotagRequire="` + row.numberAutotagRequire + `"data-floorId="` + row.floorId + `"data-numberAutotagPerGtwRequire="` + row.numberAutotagPerGtwRequire + `"data-nbGatewaysLocated="` + row.nbGatewaysLocated + `"data-nbAutotagLocated="` + row.nbAutotagLocated + `"data-floorCoverageDensity="` + row.floorCoverageDensity + `"data-buildingName="` + row.buildingName +
                                                `" class="btn btn-label-brand btn-bold btn-icon btn-circle checkingIssues ml-2" sytle = "height: 1rem; width: 1rem;">
                                                    <i class="flaticon-eye"></i>
                                                </button>
                                            </div>`
                                        }
                                        else if(floorConfigurationPercent >= self.satisfiedPercent){
                                            message +=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">` +
                                                    i18n.t("troubleshooting_checkingNearlySatisfied") +
                                                `</span>
                                            </div>
                                            <div style="width: auto; margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("analytics_details") + `"type="button" data-minOfAutotagForOneGateway="` +
                                                row.minOfAutotagForOneGateway + `"data-floorName="` + row.floorName + `"data-numberAutotagRequire="` + row.numberAutotagRequire + `"data-floorId="` + row.floorId + `"data-numberAutotagPerGtwRequire="` + row.numberAutotagPerGtwRequire + `"data-nbGatewaysLocated="` + row.nbGatewaysLocated + `"data-nbAutotagLocated="` + row.nbAutotagLocated + `"data-floorCoverageDensity="` + row.floorCoverageDensity + `"data-buildingName="` + row.buildingName +
                                                `" class="btn btn-label-brand btn-bold btn-icon btn-circle checkingIssues ml-2" sytle = "height: 1rem; width: 1rem;">
                                                    <i class="flaticon-eye"></i>
                                                </button>
                                            </div>`
                                        }
                                    }
                                    else if(row.coverage == "SATISFIED"){
                                        message +=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">` +
                                                i18n.t("troubleshooting_checkingOK") +
                                                `</span>
                                            </div>`
                                    }
                                    else if(row.coverage == "NO_LOCATED_GATEWAY"){
                                        message +=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">` +
                                                    i18n.t("troubleshooting_gatewayCheckingIssue") +
                                                `</span>
                                            </div>
                                            <div style="width: auto;margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_setLocation") + `"type="button" data-floorid="` + row.floorId +
                                                    `" class=" btn btn-label-brand btn-bold btn-sm btn-icon btn-square editGatewayLocationButton ml-2" sytle = "height: 2rem; width: 2rem;">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                </button>
                                            </div>`
                                    }
                                    else if(row.coverage == "NO_INDOOR_AREA_CREATED"){
                                        message +=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">` +
                                                    i18n.t("troubleshooting_gatewayCheckingIndoorAreaIssue") +
                                                `</span>
                                            </div>
                                            <div style="width: auto;margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_create") + `"type="button" data-floorid="` + row.floorId +
                                                    `" class=" btn btn-label-brand btn-bold btn-sm btn-icon btn-square editIndoorAreaButton ml-2" sytle = "height: 2rem; width: 2rem;">
                                                        <i class="fas fa-plus"></i>
                                                </button>
                                            </div>`
                                    }
                                    else if(row.coverage == "NO_LOCATED_AUTOCALIBRATION_TAGS"){
                                        message +=
                                            `<div style="flex: 1; width: auto; margin-left : 1.5em; margin-top: auto; margin-bottom : auto ">
                                                <span class="kt-badge kt-badge--warning kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-warning">` +
                                                    i18n.t("troubleshooting_beaconsNotLocated") +
                                                `</span>
                                            </div>
                                            <div style="width: auto;margin-top: auto; margin-bottom : auto ;flex: 0.1;">
                                                <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_setLocation") + `" type="button" data-floorid="` + row.floorId +
                                                    `" class="btn btn-icon btn-label-brand btn-bold btn-sm btn-square editBeaconLocationButton ml-2" sytle = "height: 2rem; width: 2rem;">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                </button>
                                            </div>`
                                    }
                                    return message +=  `</div>`;
                                }
                            }
                        ]
                    };

                    // Now, we are ready to build the table
                    this.beaconsCheckingTable = $("#beaconsChecking_datatable").KTDatatable(options);                    
                    this.beaconsCheckingTable.sort("floorName", "asc");

                    // Once table is build, register click events on buttons inside the table
                    this.registerEventsOnBeaconsCheckingTable();
                } else if(!this.beaconsChecking || this.beaconsChecking.length == 0) {
                    // There is no data => Show empty list icon+text
                    // No table process: Comment lines below because table destroy previously
                    this.isEmptyList = true;
                }
            } else {
                this.isEmptyList = true;
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-beaconscheckingmodal" : BeaconsCheckingModal,
        "app-beaconcheckinghealthmodal" : BeaconCheckingHealthModal,
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>