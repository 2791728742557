<template>
    <div
        class="modal fade show"
        id="beaconsCheckingModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modalHelpPlacement" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="kt-portlet__head-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                            class="kt-svg-icon"
                        >
                            <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                />
                                <path
                                    d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                />
                                <path
                                    d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                />
                            </g>
                        </svg>
                    </span>
                    <h3 class="modal-title ml-2"> {{ $t("troubleshooting_warningModalTitle") }}</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="kt-iconbox kt-iconbox--info">
                        <div class="kt-iconbox__body">
                            <div class="row">
                            </div>
                            <div
                                class="row troubleshooting_warningMessage"
                            >{{ $t("troubleshooting_warningModalSubTitle", {floorName: floorName, buildingName: buildingName}) }}
                                <!-- begin info -->
                            </div>
                            <!--begin: Datatable -->
                            <div class="troubleshooting_warningMessage">
                                <div class="kt-infobox__body">
                                    <div
                                        class="accordion accordion-light  accordion-svg-icon"
                                        id="accordionExample1"
                                    >
                                        <div v-if = "autoCalibrationTagsCheckingResponse.includes('INSUFFICIENT_AUTOTAG_NUMBER_FOR_AREA')" class="card" >
                                            <div class="card-header" id="headingOne1">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseOne1"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne1"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $t("troubleshooting_warningInsufficientBeaconsInArea") }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseOne1"
                                                class="collapse"
                                                aria-labelledby="headingOne1"
                                                data-parent="#accordionExample1"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    {{ $t("troubleshooting_warningInsufficientBeaconsInAreaMessage", {floorName: floorName, floorCoverageDensity:floorCoverageDensity, nbAutotagLocated: nbAutotagLocated , numberAutotagRequire:numberAutotagRequire}) }}
                                                    <br>
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconLocation">
                                                                {{ $t("troubleshooting_seeBeaconLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "autoCalibrationTagsCheckingResponse.includes('AUTOTAG_NOT_LOCATED_INSIDE_INDOORAREA')" class="card" >
                                            <div class="card-header" id="headingOne3">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseOne3"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne3"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $tc("troubleshooting_warningBeaconsLocalisation",autoCalibrationTagsNotWellLocated.length, autoCalibrationTagsNotWellLocated.length) }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseOne3"
                                                class="collapse"
                                                aria-labelledby="headingOne3"
                                                data-parent="#accordionExample1"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningBeaconsLocalisationMessage", {floorName: floorName, nbAutotagLocated: nbAutotagLocated}) }}</div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <table id="beaconsLocation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 60% ; margin: auto">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("act_autocalibrationtagSerialNumberLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("act_autoCalibrationTagMacAddressLabel") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="beacon in autoCalibrationTagsNotWellLocated" :key="beacon.id">
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconName(beacon.id)">
                                                                                {{ beacon.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {{ beacon.bleMacAddress}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconLocation">
                                                                {{ $t("troubleshooting_seeBeaconLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "autoCalibrationTagsCheckingResponse.includes('AUTOTAG_NOT_LOCATED_INSIDE_ONE_GEOFENCE')" class="card" >
                                            <div class="card-header" id="headingOne5">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseOne5"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne5"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $tc("troubleshooting_warningBeaconsGeofence", geofenceWithNoAutoTagInfoTable.length, geofenceWithNoAutoTagInfoTable.length) }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseOne5"
                                                class="collapse"
                                                aria-labelledby="headingOne5"
                                                data-parent="#accordionExample1"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningBeaconsGeofenceMessage", {floorName: floorName, nbGeofencesArea: geofenceWithNoAutoTagInfoTable.length}) }}</div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <table id="beaconsLocation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 60% ; margin: auto">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("geofence_geofence") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="geofence in geofenceWithNoAutoTagInfoTable" :key="geofence.id">
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGeofenceName(geofence.id)">
                                                                                {{ geofence.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconLocation">
                                                                {{ $t("troubleshooting_seeBeaconLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "autoCalibrationTagsCheckingResponse.includes('UNSATISFIED_DISTANCE_BETWEEN_AUTOTAG_AND_GATEWAY')" class="card" >
                                            <div class="card-header" id="headingOne7">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseOne7"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne7"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $tc("troubleshooting_warningBeaconsAndGatewayDistance", autocalibrationTagsWithWrongGtwDistance.length, autocalibrationTagsWithWrongGtwDistance.length)}}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseOne7"
                                                class="collapse"
                                                aria-labelledby="headingOne7"
                                                data-parent="#accordionExample1"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningBeaconsAndGatewayDistanceMessage", {floorName: floorName, nbAutotagLocated: nbAutotagLocated , nbGatewaysLocated: nbGatewaysLocated}) }}</div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <table id="beaconsLocation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 100% ; margin: auto; table-layout: fixed;">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("act_autocalibrationtag") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("gtw_bleGateway") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_distance") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(beacon, index) in autocalibrationTagsWithWrongGtwDistance" :key="index">
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconName(beacon.autocalibrationTag.id)">
                                                                                {{ beacon.autocalibrationTag.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickGatewayName(beacon.gateway.id)">
                                                                                {{ beacon.gateway.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {{beacon.distance  + " m"}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconLocation">
                                                                {{ $t("troubleshooting_seeBeaconLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "autoCalibrationTagsCheckingResponse.includes('UNSATISFIED_DISTANCE_BETWEEN_AUTOTAG_AND_WALL')" class="card" >
                                            <div class="card-header" id="headingOne8">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseOne8"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne8"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $tc("troubleshooting_warningBeaconsWallsDistance",autotagsWithWrongWallDistance.length, autotagsWithWrongWallDistance.length) }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseOne8"
                                                class="collapse"
                                                aria-labelledby="headingOne8"
                                                data-parent="#accordionExample1"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $tc("troubleshooting_warningBeaconsWallsDistanceMessage",  nbAutotagLocated, {floorName : floorName, nbAutotagLocated : nbAutotagLocated}) }}</div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <table id="beaconsLocation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 100% ; margin: auto; table-layout: fixed;">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("act_autocalibrationtag") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("wall_wallNameLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_distance") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(beacon, index) in autotagsWithWrongWallDistance" :key="index">
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconName(beacon.autocalibrationTag.id)" style = "margin-top: auto; margin-bottom: auto;">
                                                                                {{ beacon.autocalibrationTag.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink" @click="onClickWallName(beacon.wall.id)" style = "margin-top: auto; margin-bottom: auto;">
                                                                            <a class="kt-link kt-user-card-v2__name">
                                                                                {{ beacon.wall.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {{beacon.distance  + " m"}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconLocation">
                                                                {{ $t("troubleshooting_seeBeaconLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "autoCalibrationTagsCheckingResponse.includes('INSUFFICIENT_AUTOTAG_NUMBER_FOR_GATEWAY_NUMBER')" class="card" >
                                            <div class="card-header" id="headingOne2">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseOne2"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne2"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $t("troubleshooting_warningInsufficientBeaconsForGateway") }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseOne2"
                                                class="collapse"
                                                aria-labelledby="headingOne2"
                                                data-parent="#accordionExample1"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    {{ $t("troubleshooting_warningInsufficientBeaconsForGatewayMessage" , {floorName: floorName, nbAutotagLocated: nbAutotagLocated, nbGatewaysLocated: nbGatewaysLocated, numberAutotagPerGtwRequire: numberAutotagPerGtwRequire}) }}
                                                    <br>
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconLocation">
                                                                {{ $t("troubleshooting_seeBeaconLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "autoCalibrationTagsCheckingResponse.includes('UNSATISFIED_DISTANCE_BETWEEN_AUTOTAG')" class="card" >
                                            <div class="card-header" id="headingOne6">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseOne6"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne6"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $tc("troubleshooting_warningBeaconsDistance", autocalibrationTagsWithWrongDistance.length, autocalibrationTagsWithWrongDistance.length) }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseOne6"
                                                class="collapse"
                                                aria-labelledby="headingOne6"
                                                data-parent="#accordionExample1"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningBeaconsDistanceMessage", {floorName: floorName, nbAutotagLocated: nbAutotagLocated}) }}</div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <table id="beaconsLocation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 80% ; margin: auto; table-layout: fixed;">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("act_autocalibrationtagSerialNumberLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("act_autoCalibrationTagMacAddressLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_distance") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(beacon, index) in autocalibrationTagsWithWrongDistance" :key="index">
                                                                <td>
                                                                    <div class="kt-user-card-v2">
                                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconName(beacon.autocalibrationTag.id)">
                                                                                {{ beacon.autocalibrationTag.name}}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {{ beacon.autocalibrationTag.bleMacAddress}}
                                                                </td>
                                                                <td>
                                                                    <div v-if = "beacon.distance == '!' ">
                                                                        <a href = "#" class="kt-link" style="cursor:pointer ;" @click="printMessage">
                                                                            {{" > 8 m * "}}
                                                                        </a>
                                                                    </div>
                                                                    <div v-else>
                                                                        {{beacon.distance + " m "}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <br>
                                                    <div v-if = "displayAutoCalibrationTagFarAwayExplanations " class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningBeaconsDistanceMessage2") }}</div>
                                                    <br>
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconLocation">
                                                                {{ $t("troubleshooting_seeBeaconLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if = "autoCalibrationTagsCheckingResponse.includes('AUTOTAG_AT_DIFFERENT_HEIGHT')" class="card" >
                                            <div class="card-header" id="headingOne4">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseOne4"
                                                    aria-expanded="false"
                                                    aria-controls="collapseOne4"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $t("troubleshooting_warningBeaconsFloorElevation") }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseOne4"
                                                class="collapse"
                                                aria-labelledby="headingOne4"
                                                data-parent="#accordionExample1"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningBeaconsFloorElevationMessage", {floorName: floorName, nbAutotagLocated: nbAutotagLocated}) }}
                                                        <!-- begin info -->
                                                    </div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <table id="beaconsFloorElevation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 60% ; margin: auto">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("troubleshooting_height") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_beaconsNumber") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(beacon, index) in autoTagFloorElevationResponseTable" :key="index">
                                                                <td>{{ beacon.height + " m" }}</td>
                                                                <td>
                                                                    {{ beacon.nbBeacons }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <br>
                                                    <div class="kt-user-card-v2">
                                                        <div class="kt-user-card-v2__details openResourceLink">
                                                            <a class="kt-link kt-user-card-v2__name" @click="onClickBeaconLocation">
                                                                {{ $t("troubleshooting_seeBeaconLocation")}}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end: Datatable -->
                        </div>
                    </div>
                    <div class="flex-grow-1 flex-shrink-0">
                        <div class="progress mt-2 mb-2" style=" height : auto">
                            <div v-bind:class="classProgressBar" role="progressbar" v-bind:style="styleProgressBar" aria-valuenow= "floorConfigurationPercent" aria-valuemin="0" aria-valuemax="100">
                                {{floorConfigurationPercent}}%
                            </div>
                        </div>
                    </div>
                    <span class="font-weight-bold" style ="color: rgb(80, 79, 79);">{{$tc("troubleshooting_progressBarExplanations", autoCalibrationTagsCheckingResponse.length,{nbIssueResolve: autoCalibrationTagsCheckingResponse.length})}}</span>
                </div>
                <div class="modal-footer">
                    <button
                        id="aboutOkButton"
                        type="button"
                        class="btn btn-brand"
                        data-dismiss="modal"
                    >{{ $t("common_okThanks") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    data() {
        return {
            autoTagFloorElevationResponseTable : [],
            geofenceWithNoAutoTagInfoTable : [],
            displayAutoCalibrationTagFarAwayExplanations : false,
            nbIssueResolved : 0,
            styleProgressBar: { width: "0%", color: "black", "margin-left": "10px"},
            classProgressBar : "progress-bar bg-danger"
        };
    },
    props: {
        minOfAutotagForOneGateway : {
            type : String
        },
        numberAutotagRequire : {
            type : String
        },
        floorName : {
            type : String
        },
        numberAutotagPerGtwRequire : {
            type : String
        },
        nbAutotagLocated : {
            type : String
        },
        nbGatewaysLocated : {
            type : String
        },
        autoTagFloorElevationResponse : {
            type : Object
        },
        autoCalibrationTagsCheckingResponse : {
            type : Array
        },
        autoCalibrationTagsNotWellLocated : {
            type : Array
        },
        floorCoverageDensity : {
            type : String
        },
        siteId : {
            type : String
        },
        buildingName : {
            type : String
        },
        geofenceWithNoAutoTagTable : {
            type : Object
        },
        floorId : {
            type : String
        },
        autocalibrationTagsWithWrongDistance : {
            type : Array
        },
        autocalibrationTagsWithWrongGtwDistance : {
            type : Array
        },
        satifiedPercent : {
            type : Number
        },
        excellentPercent : {
            type : Number
        },
        floorConfigurationPercent : {
            type : Number
        },
        autotagsWithWrongWallDistance : {
            type : Array
        }
    },
    mounted: function(){
        $("#beaconsCheckingModal").on('hidden.bs.modal', function() {
            const findOpenElement = $('.collapse.show');
            if (findOpenElement) {
                findOpenElement.collapse('hide');
            }
        })
    },
    watch:{

        // take back the keys and the values of the object autoTagFloorElevationResponse
        autoCalibrationTagsCheckingResponse(){
            console.log("Component(beaconscheckingmodal)::watch(autoCalibrationTagsCheckingResponse) called with : ", this.autoCalibrationTagsCheckingResponse);
            this.nbIssueResolvedCount();
        },

        // Watch the data of floor elevation of autocalibration tag which is given in props
        autoTagFloorElevationResponse(){
            console.log("Component(beaconscheckingmodal)::watch(autoTagFloorElevationResponse) called with : ", this.autoTagFloorElevationResponse);
            this.autoTagFloorElevationResponseTable = [];
            const keys = Object.keys(this.autoTagFloorElevationResponse);
            const values = Object.values(this.autoTagFloorElevationResponse);
            for(let i = 0 ; i < values.length ; i++){

                const dataStored = {
                    nbBeacons : values[i],
                    height : keys[i]
                };

                this.autoTagFloorElevationResponseTable.push(dataStored);
            }
        },

        // take back the keys and the values of the object geofenceWithNoAutoTagTable
        geofenceWithNoAutoTagTable() {
            console.log("Component(beaconscheckingmodal)::watch(geofenceWithNoAutoTagTable) called with : ", this.geofenceWithNoAutoTagTable);
            this.geofenceWithNoAutoTagInfoTable = [];
            const values = Object.values(this.geofenceWithNoAutoTagTable);
            for(let i = 0 ; i < values.length ; i++){
                const dataStored = {
                    name : values[i].name,
                    id : values[i].id
                };

                this.geofenceWithNoAutoTagInfoTable.push(dataStored);
            }
        }
    },
    methods: {

        // function Onclick event to go on edit autocalibration tag location vue
        onClickBeaconName(beaconId) {
            if((this.autoCalibrationTagsNotWellLocated && this.autoCalibrationTagsNotWellLocated.length > 0) || (this.autocalibrationTagsWithWrongDistance && this.autocalibrationTagsWithWrongDistance.length > 0) || (this.autocalibrationTagsWithWrongGtwDistance && this.autocalibrationTagsWithWrongGtwDistance.length > 0) || (this.autotagsWithWrongWallDistance && this.autotagsWithWrongWallDistance.length > 0)){

                this.$router.push({
                    name: "autocalibrationtagsLocation",
                    params: { siteId: this.siteId, highlight: beaconId }
                });
                $("#beaconsCheckingModal").modal("hide");
            }
        },

        // function Onclick event to go on edit geofence vue
        onClickGeofenceName(geofenceId) {
            if(this.geofenceWithNoAutoTagInfoTable && this.geofenceWithNoAutoTagInfoTable.length > 0){

                const keyStrorage = this.siteId + "_geofencesDefinition_lastDisplayedFloorId";
                localStorage.setItem(keyStrorage, this.floorId);
                // Hide and destroy element’s tooltip
                $('[data-toggle="kt-tooltip"]').tooltip("dispose");

                this.$router.push({
                    name: "geofences",
                    params: { siteId: this.siteId, highlight: geofenceId }
                });
                $("#beaconsCheckingModal").modal("hide");
            }
        },

        // Move to gateway location page when user clicks on gateway name and init the highlight with this gateway id
        onClickGatewayName(gatewayId) {
            if(this.autocalibrationTagsWithWrongGtwDistance && this.autocalibrationTagsWithWrongGtwDistance.length > 0){

                this.$router.push({
                    name: "gatewaysLocation",
                    params: { siteId: this.siteId, highlight: gatewayId}
                });
                $("#beaconsCheckingModal").modal("hide");
            }
        },

        // function Onclick event to go to wall location vue and search the right wall in the list
        onClickWallName(wallId) {
            if(this.autotagsWithWrongWallDistance && this.autotagsWithWrongWallDistance.length > 0){

                const keyStrorage = this.siteId + "_wallsDefinition_lastDisplayedFloorId";
                localStorage.setItem(keyStrorage, this.floorId);

                this.$router.push({
                    name : "walls",
                    params : {siteId : this.siteId, wallId : wallId }
                })
                $("#beaconsCheckingModal").modal("hide");
            }

        },

        // Move to autocalibration location page when user clicks on autocalibration name and init the highlight with this autocalibration id
        onClickBeaconLocation(){
            const keyStrorage = this.siteId + "_autocalibrationtagsLocation_lastDisplayedFloorId";
            localStorage.setItem(keyStrorage, this.floorId)
            this.$router.push({
                name : "autocalibrationtagsLocation",
                params : {siteId : this.siteId}
            });
            $("#beaconsCheckingModal").modal("hide");
        },

        // Toggle on message showing
        printMessage() {
            this.displayAutoCalibrationTagFarAwayExplanations = !this.displayAutoCalibrationTagFarAwayExplanations;
        },

        //give the percent of the configuration status
        nbIssueResolvedCount() {
            if (this.floorConfigurationPercent == 0) {
                this.styleProgressBar = { width: this.floorConfigurationPercent + "%", color: "red", "font-weight": "600" };
                this.classProgressBar = "progress-bar bg-danger"
            } else if (this.floorConfigurationPercent > 0 && this.floorConfigurationPercent < 40) {
                this.styleProgressBar = { width: this.floorConfigurationPercent + "%", color: "white", "font-weight": "600" };
                this.classProgressBar = "progress-bar bg-danger"
            } else if(this.floorConfigurationPercent >= 40 && this.floorConfigurationPercent < this.satifiedPercent){
                this.styleProgressBar = { width: this.floorConfigurationPercent + "%", color: "white", "font-weight": "600" };
                this.classProgressBar = "progress-bar bg-warning"
            } else {
                this.styleProgressBar = { width: this.floorConfigurationPercent + "%", color: "white", "font-weight": "600" };
                this.classProgressBar = "progress-bar bg-success"
            }
        }
    }
};
</script>

<style scoped>
.troubleshooting_warningMessage{
    margin: 2px 1em 0 auto;
    color: rgb(80, 79, 79);
    font-weight: 100;
    text-align: start;
}
</style>