<template>
    <div
        class="modal fade show"
        id="beaconscheckinghealthModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modalHelpPlacement" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="kt-portlet__head-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                            class="kt-svg-icon"
                        >
                            <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                />
                                <path
                                    d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                />
                                <path
                                    d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                />
                            </g>
                        </svg>
                    </span>
                    <h3 class="modal-title ml-2"> {{ $t("troubleshooting_warningBeaconCheckingHealthModalTitle") }}</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="kt-iconbox kt-iconbox--info">
                        <div class="kt-iconbox__body">
                            <div class="row">
                            </div>
                            <!--begin: Datatable -->
                            <div class="troubleshooting_warningMessage">
                                <div class="kt-infobox__body">
                                    <div
                                        class="accordion accordion-light  accordion-svg-icon"
                                        id="accordionExample2"
                                    >
                                        <div v-if = "autocalibrationTagsHealthOfFloorTable && autocalibrationTagsHealthOfFloorTable.length > 0 " class="card">
                                            <div class="card-header" id="headingTwo1">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseTwo1"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo1"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $t("troubleshooting_warningBeaconCheckingHealthModalSubTitle",{floorName: floorName, nbAutotagLocated: nbAutotagLocated}) }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseTwo1"
                                                class="collapse"
                                                aria-labelledby="headingTwo1"
                                                data-parent="#accordionExample2"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningBeaconCheckingHealthModalExplanation", {floorName: floorName}) }}</div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <div class="row formContainer" v-if="coverageTypes && coverageTypes.length > 1 ">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("troubleshooting_beaconCoverageCheckboxChoice") }}</label> <br />
                                                            <div class="kt-checkbox-list row checkboxGroupDiv">
                                                                <label class="kt-checkbox kt-checkbox--brand kt-checkbox--bold kt-checkbox--danger text-danger mr-4" v-if = "coverageTypes.includes('OUT')" style = "font-weight: 500;">
                                                                    <input type="checkbox" name="coverageType" v-model="vDisplayType" value="OUT" checked/> {{ $t("troubleshooting_tagStatusNone") }}
                                                                    <span></span>
                                                                </label>
                                                                <label class="kt-checkbox kt-checkbox--brand kt-checkbox--bold  kt-checkbox--warning text-warning mr-4" v-if = "coverageTypes.includes('BAD')" style = "font-weight: 500;">
                                                                    <input type="checkbox" name="coverageType" v-model="vDisplayType" value="BAD" checked /> {{ $t("troubleshooting_tagStatusBad") }}
                                                                    <span></span>
                                                                </label>
                                                                <label class="kt-checkbox kt-checkbox--brand kt-checkbox--bold kt-checkbox--success text-success mr-4" v-if = "coverageTypes.includes('GOOD')" style = "font-weight: 500;">
                                                                    <input type="checkbox" name="coverageType" v-model="vDisplayType" value="GOOD" checked /> {{ $t("troubleshooting_tagStatusGood") }}
                                                                    <span></span>
                                                                </label>
                                                                <label class="kt-checkbox kt-checkbox--brand kt-checkbox--bold kt-checkbox--success text-success mr-4" v-if = "coverageTypes.includes('EXCELLENT')" style = "font-weight: 500;">
                                                                    <input type="checkbox" name="coverageType" v-model="vDisplayType" value="EXCELLENT" checked /> {{ $t("troubleshooting_tagStatusExcellent") }}
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table v-if="!isEmptyList" id="gatewayLocation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 100% ; margin: auto">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("act_autocalibrationtagSerialNumberLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("act_autoCalibrationTagMacAddressLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_beaconNumberAPCoverageLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_tagStatus") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="beacon in filterBeaconHealthTable" :key="beacon.id">
                                                                <td>
                                                                    {{ beacon.serialNumber}}
                                                                </td>
                                                                <td>
                                                                    {{ beacon.macAddress}}
                                                                </td>
                                                                <td>
                                                                    <div v-if="beacon.coverage.length > 1">
                                                                        {{beacon.coverage.length + " " +$t( "troubleshooting_tagMultipleGatewayScanned")}}
                                                                    </div>
                                                                    <div v-else-if="beacon.coverage.length == 1">
                                                                        {{$t( "troubleshooting_tagOneGatewayScanned")}}
                                                                    </div>
                                                                    <div v-else>
                                                                        {{$t( "troubleshooting_tagNoGatewayScanned")}}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div v-if="beacon.coverage.length === 0" style="font-weight: 500; cursor : pointer;">
                                                                        <a class="kt-link text-danger" @click="printOutOfCoverageMessage(beacon.serialNumber)">
                                                                            {{$t("troubleshooting_tagStatusNone")}}
                                                                            <i class="icon-xl far fa-frown text-danger "></i>
                                                                        </a>
                                                                    </div>
                                                                    <div v-if="beacon.coverage.length === 1 || beacon.coverage.length === 2" style=" font-weight: 500; cursor : pointer;">
                                                                        <a class="kt-link text-warning" @click="printBadMessage(beacon.serialNumber)">
                                                                            {{$t("troubleshooting_tagStatusBad")}}
                                                                            <i class="icon-xl far fa-meh text-warning"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div v-if="beacon.coverage.length === 3" style=" font-weight: 500;">
                                                                        <a class="text-success">
                                                                            {{$t("troubleshooting_tagStatusGood")}}
                                                                            <i class=" icon-xl far fa-smile text-success"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div v-if="beacon.coverage.length > 3" style=" font-weight: 500;">
                                                                        <a class="text-success">
                                                                            {{$t("troubleshooting_tagStatusExcellent")}}
                                                                            <i class=" icon-xl far fa-smile text-success"></i>
                                                                        </a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--end: Datatable -->
                                                    <!--begin emptyList component-->
                                                    <app-emptylist targetObjectName="BEACONSHEALTH_LIST" mainTextKey="troubleshooting_emptyBeaconsHealthFilter" imgName="empty_autocalibrationtag.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                                    <!--end emptyList component-->
                                                    <br/>
                                                    <div v-if="displayHealtBadExplanationMessage" class="explanationDiv">
                                                        <div>
                                                            <i class="icon-xl far fa-meh text-warning"></i>
                                                            {{$t("troubleshooting_beaconStatusBadExplanations")}}
                                                            <br>
                                                            <br>
                                                            <ul class="beaconCheckList">
                                                                <li>{{$t("troubleshooting_beaconStatusBadCheck1")}}</li>
                                                                <li>{{$t("troubleshooting_beaconStatusBadCheck2")}}</li>
                                                                <li>{{$t("troubleshooting_beaconStatusBadCheck3")}}</li>
                                                            </ul>
                                                            {{$t("troubleshooting_beaconStatusBadCheck4")}}
                                                        </div>
                                                        <br>
                                                        <span class="clickablelink kt-link" @click="seeTagGatewayCoverage">
                                                            {{$t("troubleshooting_seeBeaconStatusDetails", {beaconSerialNumber: beaconSerialNumber})}}
                                                        </span>
                                                        <span class="kt-link row clickablelink" @click="onClickEditBeacon">
                                                            {{$t("troubleshooting_editBeacon", {beaconSerialNumber: beaconSerialNumber})}}
                                                        </span>
                                                    </div>
                                                    <div v-if="displayHealtOutOfCoverageExplanationMessage" class="explanationDiv">
                                                        <div>
                                                            <i class="icon-xl far fa-frown text-danger"></i>
                                                            {{$t("troubleshooting_beaconStatusOutOfCoverageExplanations")}}
                                                            <br>
                                                            <br>
                                                            <ul class="beaconCheckList">
                                                                <li>{{$t("troubleshooting_beaconStatusOutOfCoverageCheck1")}}</li>
                                                                <li>{{$t("troubleshooting_beaconStatusOutOfCoverageCheck2")}}</li>
                                                                <li>{{$t("troubleshooting_beaconStatusOutOfCoverageCheck3")}}</li>
                                                                <li>{{$t("troubleshooting_beaconStatusOutOfCoverageCheck4")}}</li>
                                                            </ul>
                                                            {{$t("troubleshooting_beaconStatusOutOfCoverageCheck5")}}
                                                        </div>
                                                        <br>
                                                        <span class="clickablelink kt-link" @click="seeTagGatewayCoverage">
                                                            {{$t("troubleshooting_seeBeaconStatusDetails", {beaconSerialNumber: beaconSerialNumber})}}
                                                        </span>
                                                        <span class="kt-link row clickablelink" @click="onClickEditBeacon">
                                                            {{$t("troubleshooting_editBeacon", {beaconSerialNumber: beaconSerialNumber})}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        id="aboutOkButton"
                        type="button"
                        class="btn btn-brand"
                        data-dismiss="modal"
                        @click="onCloseModal()"
                    >{{ $t("common_okThanks") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmptyList from "./../utils/emptylist.vue";
export default {

    data (){
        return {
            displayHealtBadExplanationMessage : false,
            displayHealtOutOfCoverageExplanationMessage : false,
            beaconSerialNumber : "",
            vDisplayType: [],
            filterBeaconHealthTable: [],
            coverageTypes: [],
            isEmptyList: false,
        };
    },

    props: {
        autocalibrationTagsHealthOfFloorTable : {
            type : Array
        },
        floorName : {
            type : String,
        },
        buildingName : {
            type : String
        },
        siteId : {
            type : String
        },
        nbGatewaysLocated : {
            type : String
        },
        nbAutotagLocated : {
            type : String
        },
        floorId : {
            type : String
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["user"]),

    },
    watch: {
        autocalibrationTagsHealthOfFloorTable() {
            console.log("Component(beaconscheckinghealtmodal)::watch(autocalibrationTagsHealthOfFloorTable) called with : ", this.autocalibrationTagsHealthOfFloorTable);
            // Init the display items with autocalibration tag props
            this.filterBeaconHealthTable = [...this.autocalibrationTagsHealthOfFloorTable];
            // Build the coverage type
            this.coverageTypes = [];
            const findOut = this.autocalibrationTagsHealthOfFloorTable.filter((beacon) => beacon.coverage && beacon.coverage.length === 0);
            const findBad = this.autocalibrationTagsHealthOfFloorTable.filter((beacon) => beacon.coverage && beacon.coverage.length === 1 || beacon.coverage.length === 2);
            const findGood = this.autocalibrationTagsHealthOfFloorTable.filter((beacon) => beacon.coverage && beacon.coverage.length === 3);
            const findExcellent = this.autocalibrationTagsHealthOfFloorTable.filter((beacon) => beacon.coverage && beacon.coverage.length > 3);
            if(findOut && findOut.length > 0) {
                this.coverageTypes.push("OUT");
                this.vDisplayType.push("OUT");
            }
            if(findBad && findBad.length > 0) {
                this.coverageTypes.push("BAD");
                this.vDisplayType.push("BAD");
            }
            if(findGood && findGood.length > 0) {
                this.coverageTypes.push("GOOD");
                this.vDisplayType.push("GOOD");
            }
            if(findExcellent && findExcellent.length > 0) {
                this.coverageTypes.push("EXCELLENT");
                this.vDisplayType.push("EXCELLENT");
            }
        },

        vDisplayType(value) {
            console.log("Component(beaconscheckinghealtmodal)::watch(vDisplayType) called with : ", value);
            if(this.vDisplayType.length == 0){
                this.isEmptyList = true;
            }
            // Init the array to display to empty
            this.filterBeaconHealthTable = [];
            // Loop on each status type and find if this status is selected
            for (let statut of this.coverageTypes) {
                if (this.vDisplayType.includes(statut)) {
                    // Define the array to get find element
                    this.isEmptyList = false;
                    let findData = [];
                    // Analyse the statut type
                    switch(statut) {
                        case "OUT":
                            findData = this.autocalibrationTagsHealthOfFloorTable.filter((beacon) => beacon.coverage && beacon.coverage.length === 0);
                            break;
                        case "BAD":
                            findData = this.autocalibrationTagsHealthOfFloorTable.filter((beacon) => beacon.coverage && beacon.coverage.length === 1 || beacon.coverage.length === 2);
                            break;
                        case "EXCELLENT":
                            findData = this.autocalibrationTagsHealthOfFloorTable.filter((beacon) => beacon.coverage && beacon.coverage.length > 3);
                            break;
                        case "GOOD":
                            findData = this.autocalibrationTagsHealthOfFloorTable.filter((beacon) => beacon.coverage && beacon.coverage.length === 3);
                            break;
                    }
                    // Add find data inside the array which will be displayed
                    this.filterBeaconHealthTable = [...this.filterBeaconHealthTable, ...findData];
                }
            }
        }
    },
    mounted: function(){
        $("#beaconscheckinghealthModal").on('hidden.bs.modal', function() {
            const findOpenElement = $('.collapse.show');
            if (findOpenElement) {
                findOpenElement.collapse('hide');
            }
        })
    },
    methods: {
        // Function to display the message bellow the beacon statut when the statut is bad
        printBadMessage(beaconSerialNumber) {
            this.displayHealtBadExplanationMessage = ! this.displayHealtBadExplanationMessage;
            if(this.displayHealtOutOfCoverageExplanationMessage){
                this.displayHealtOutOfCoverageExplanationMessage = false;
            }
            this.beaconSerialNumber = beaconSerialNumber;
        },

        // Function to display the message bellow the beacon statut when the statut is out of coverage
        printOutOfCoverageMessage(beaconSerialNumber) {
            this.displayHealtOutOfCoverageExplanationMessage= !this.displayHealtOutOfCoverageExplanationMessage;
            if(this.displayHealtBadExplanationMessage){
                this.displayHealtBadExplanationMessage = false;
            }
            this.beaconSerialNumber = beaconSerialNumber;
        },

        // Move to site beacon heath with init the search value to specific beacon when user clicks on beacon serial number
        seeTagGatewayCoverage() {
            this.$router.push({
                name: "siteBeaconsHealth",
                params: { siteId: this.siteId, search : this.beaconSerialNumber}
            });
            $("#beaconscheckinghealthModal").modal("hide");
        },

        // Move to site beacons list with init the search value to specific beacon when user clicks on beacon serial number
        onClickEditBeacon(){
            this.$router.push({
                name: "autocalibrationtags",
                params: { siteId: this.siteId, search : this.beaconSerialNumber },
            });
            $("#beaconscheckinghealthModal").modal("hide");
        },

        onCloseModal() {
            this.displayHealtBadExplanationMessage = false;
            this.displayHealtOutOfCoverageExplanationMessage = false;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList
    }
};
</script>

<style scoped>
    .beaconCheckList {
        list-style-type: circle;
        margin-left: 40px
    }    
    .troubleshooting_warningMessage{
        margin: 2px 1em 0 auto;
        color: rgb(80, 79, 79);
        font-weight: 100;
        text-align: start;
    }
    .clickablelink {
        cursor: pointer;
    }
    .explanationDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .checkboxGroupDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        align-items: center;
    }

    .formContainer {
        text-align: center;
        margin-bottom: -10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .kt-checkbox-list .kt-checkbox:last-child {
        margin-bottom: 10px;
    }
</style>