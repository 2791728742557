<template>
    <div
        class="modal fade show"
        id="gatewayscheckinghealthModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modalHelpPlacement" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="kt-portlet__head-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                            class="kt-svg-icon"
                        >
                            <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                />
                                <path
                                    d="M19.366142,13.9305937 L17.2619853,15.6656848 C15.9733542,14.1029531 14.0626842,13.1818182 11.9984835,13.1818182 C9.94104045,13.1818182 8.03600715,14.0968752 6.74725784,15.6508398 L4.64798148,13.9098472 C6.44949126,11.7375997 9.12064835,10.4545455 11.9984835,10.4545455 C14.8857906,10.4545455 17.5648042,11.7460992 19.366142,13.9305937 Z M23.5459782,10.4257575 L21.4473503,12.1675316 C19.1284914,9.37358605 15.6994058,7.72727273 11.9984835,7.72727273 C8.30267753,7.72727273 4.87785708,9.36900008 2.55893241,12.1563207 L0.462362714,10.4120696 C3.29407133,7.00838857 7.48378666,5 11.9984835,5 C16.519438,5 20.7143528,7.01399004 23.5459782,10.4257575 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                />
                                <path
                                    d="M15.1189503,17.3544974 L13.0392442,19.1188213 L11.9619232,20 L10.9331836,19.1485815 L8.80489611,17.4431757 C9.57552634,16.4814558 10.741377,15.9090909 11.9984835,15.9090909 C13.215079,15.9090909 14.347452,16.4450896 15.1189503,17.3544974 Z"
                                    id="Oval-21"
                                    fill="#000000"
                                />
                            </g>
                        </svg>
                    </span>
                    <h3 class="modal-title ml-2"> {{ $t("troubleshooting_warningGatewayCheckingHealthModalTitle") }}</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="kt-iconbox kt-iconbox--info">
                        <div class="kt-iconbox__body">
                            <div class="row">
                            </div>
                            <!--begin: Datatable -->
                            <div class="troubleshooting_warningMessage">
                                <div class="kt-infobox__body">
                                    <div
                                        class="accordion accordion-light  accordion-svg-icon"
                                        id="accordionExample2"
                                    >
                                        <div v-if = "gatewaysHealthOfFloorTable && gatewaysHealthOfFloorTable.length > 0 " class="card">
                                            <div class="card-header" id="headingTwo1">
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    data-target="#collapseTwo1"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo1"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <polygon
                                                                points="0 0 24 0 24 24 0 24"
                                                            ></polygon>
                                                            <path
                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            ></path>
                                                            <path
                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                                opacity="0.3"
                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {{ $t("troubleshooting_warningGatewayCheckingHealthModalSubTitle",{floorName: floorName, nbGateways: nbGateways}) }}
                                                </div>
                                            </div>
                                            <div
                                                id="collapseTwo1"
                                                class="collapse"
                                                aria-labelledby="headingTwo1"
                                                data-parent="#accordionExample2"
                                                style=""
                                            >
                                                <div class="card-body ml-4">
                                                    <div
                                                        class="row troubleshooting_warningMessage"
                                                    >{{ $t("troubleshooting_warningGatewayCheckingHealthModalExplanation", {floorName: floorName}) }}</div>
                                                    <br>
                                                    <!--begin: Datatable -->
                                                    <div class="row formContainer" v-if="coverageTypes && coverageTypes.length > 1 ">
                                                        <div class="form-group validated">
                                                            <label class="colorGeonotif">{{ $t("troubleshooting_gatewayCoverageCheckboxChoice") }} <br /></label>
                                                            <div class="kt-checkbox-list row checkboxGroupDiv">
                                                                <label class="kt-checkbox kt-checkbox--brand kt-checkbox--bold kt-checkbox--danger text-danger mr-4" v-if = "coverageTypes.includes('DOWN')" style = "font-weight: 500;">
                                                                    <input type="checkbox" name="coverageType" v-model="vDisplayType" value="DOWN" checked/> {{ $t("troubleshooting_gatewayStatusDown") }}
                                                                    <span></span>
                                                                </label>
                                                                <label class="kt-checkbox kt-checkbox--brand kt-checkbox--bold kt-checkbox--success text-success mr-4" v-if = "coverageTypes.includes('UP')" style = "font-weight: 500;">
                                                                    <input type="checkbox" name="coverageType" v-model="vDisplayType" value="UP" checked/> {{ $t("troubleshooting_gatewayStatusUp") }}
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table v-if = "!isEmptyList" id="gatewayLocation_datatable" text-align="center" class="table table-bordered table-hover" style="margin-top: 10px; text-align: center ; width: 80% ; margin: auto">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>
                                                                    {{ $t("troubleshooting_gatewayNameLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_gatewayMacAddressLabel") }}
                                                                </th>
                                                                <th>
                                                                    {{ $t("troubleshooting_gatewayStatus") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="gateway in filterGatewayHealthTable" :key="gateway.id">
                                                                <td>
                                                                    {{ gateway.name}}
                                                                </td>
                                                                <td>
                                                                    {{ gateway.bleMacAddress}}
                                                                </td>
                                                                <td>
                                                                    <div v-if = "gateway.lastEmission && gateway.lastEmission != -1"  class = "text-success" style=" color: green; font-weight: 500;">
                                                                        {{$t("troubleshooting_gatewayStatusUp")}}
                                                                        <i class="icon-xl la la-check"></i>
                                                                    </div>
                                                                    <div v-else style ="cursor : pointer; font-weight: 500;">
                                                                        <a class="kt-link kt-user-card-v2__name text-danger" @click="printDownMessage(gateway.name)">
                                                                            {{$t("troubleshooting_gatewayStatusDown")}}
                                                                            <i class="icon-xl la la-warning text-danger warningRequireAttention"></i>
                                                                        </a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--begin emptyList component-->
                                                    <app-emptylist targetObjectName="BEACONSHEALTH_LIST" mainTextKey="troubleshooting_emptygatewaysHealthFilter" imgName="empty_gateway.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                                    <!--end emptyList component-->
                                                    <!--end: Datatable -->
                                                    <br/>
                                                    <div v-if="displayHealtExplanationMessage" class="explanationDiv">
                                                        <div>
                                                            <i class="icon-xl la la-warning text-danger warningRequireAttention"></i>
                                                            {{$t("troubleshooting_gatewayStatusDownExplanations")}}
                                                            <br>
                                                            <br>
                                                            <ul class="gatewayCheckList">
                                                                <li>{{$t("troubleshooting_gatewayStatusDownCheck1")}}</li>
                                                                <li>{{$t("troubleshooting_gatewayStatusDownCheck2")}}</li>
                                                                <li>{{$t("troubleshooting_gatewayStatusDownCheck3")}}</li>
                                                                <li>{{$t("troubleshooting_gatewayStatusDownCheck4")}}</li>
                                                            </ul>
                                                            {{$t("troubleshooting_gatewayStatusDownCheck5")}}
                                                        </div>
                                                        <br>
                                                        <span class="kt-link row clickablelink" @click="onClickEditGateway">
                                                            {{$t("troubleshooting_editGateway",{gatewayName : gatewayName})}}
                                                        </span>
                                                        <span class="kt-link row clickablelink" @click="onClickGatewayName">
                                                            {{$t("troubleshooting_seeGatewayStatusDetails",{gatewayName : gatewayName})}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        id="aboutOkButton"
                        type="button"
                        class="btn btn-brand"
                        data-dismiss="modal"
                        @click="onCloseModal()"
                    >{{ $t("common_okThanks") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters } from "vuex";
import EmptyList from "./../utils/emptylist.vue";
export default {

    data (){
        return {
            displayHealtExplanationMessage : false,
            gatewayName : "",
            vDisplayType: [],
            filterGatewayHealthTable: [],
            coverageTypes: [],
            isEmptyList : false
        };
    },

    props: {
        gatewaysHealthOfFloorTable : {
            type : Array
        },
        floorName : {
            type : String,
        },
        buildingName : {
            type : String
        },
        siteId : {
            type : String
        },
        nbGateways : {
            type : String
        }

    },
    watch : {

        gatewaysHealthOfFloorTable() {
            console.log("Component(gatewaycheckinghealthmodal)::watch(gatewaysHealthOfFloorTable) called with : ", this.gatewaysHealthOfFloorTable);
            // Init the display items with gateway health props
            this.filterGatewayHealthTable = [...this.gatewaysHealthOfFloorTable];
            // Build the coverage type
            this.coverageTypes = [];
            const findDown = this.gatewaysHealthOfFloorTable.filter((gateway) => !gateway.lastEmission ||  (gateway.lastEmission && gateway.lastEmission == -1));
            const findUp = this.gatewaysHealthOfFloorTable.filter((gateway) => gateway.lastEmission && gateway.lastEmission != -1);
            if(findDown && findDown.length > 0){
                this.coverageTypes.push("DOWN");
                this.vDisplayType.push("DOWN");
            }
            if(findUp && findUp.length > 0){
                this.coverageTypes.push("UP");
                this.vDisplayType.push("UP");
            }

        },

        vDisplayType(value) {
            console.log("Component(gatewaycheckinghealthmodal)::watch(vDisplayType) called with : ", value);

            if(this.vDisplayType.length == 0){
                this.isEmptyList = true;
            }
            // Init the array to display to empty
            this.filterGatewayHealthTable = [];
            // Loop on each status type and find if this status is selected
            for(let status of this.coverageTypes){
                if(this.vDisplayType.includes(status)){
                    this.isEmptyList = false;
                    let findData = [];
                    switch(status) {
                        case "UP":
                            findData = this.gatewaysHealthOfFloorTable.filter((gateway) => gateway.lastEmission && gateway.lastEmission != -1);
                            break;
                        case "DOWN":
                            findData = this.gatewaysHealthOfFloorTable.filter((gateway) => !gateway.lastEmission || (gateway.lastEmission && gateway.lastEmission == -1));
                            break;
                    }
                    this.filterGatewayHealthTable = [...this.filterGatewayHealthTable, ...findData];
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["user"]),

    },
    mounted: function(){
        $("#gatewayscheckinghealthModal").on('hidden.bs.modal', function() {
            const findOpenElement = $('.collapse.show');
            if (findOpenElement) {
                findOpenElement.collapse('hide');
            }
        })
    },
    methods: {

        // function Onclick event to go to edit gateway health vue and highlight the right gateway
        onClickGatewayName() {
            if(this.gatewaysHealthOfFloorTable && this.gatewaysHealthOfFloorTable.length > 0){
                this.$router.push({
                    name: "siteGatewaysHealth",
                    params: { siteId: this.siteId, search : this.gatewayName }
                });
                $("#gatewayscheckinghealthModal").modal("hide");
            }
        },

        // Change the name of gateway which will be displayed in detail message
        printDownMessage(gatewayName) {
            this.displayHealtExplanationMessage = ! this.displayHealtExplanationMessage;
            this.gatewayName = gatewayName;
        },

        // function Onclick event to go to gateway list vue and highlight the right gateway
        onClickEditGateway() {
            this.$router.push({
                name: "gateways",
                params: { siteId: this.siteId, search : this.gatewayName }
            });
            $("#gatewayscheckinghealthModal").modal("hide");
        },
        
        onCloseModal() {
            this.displayHealtExplanationMessage = false;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList
    }
};
</script>

<style scoped>
    .gatewayCheckList {
        list-style-type: circle;
        margin-left: 40px
    }
    .troubleshooting_warningMessage{
        margin: 2px 1em 0 auto;
        color: rgb(80, 79, 79);
        font-weight: 100;
        text-align: start;
    }
    .clickablelink {
        cursor: pointer;
    }
    .explanationDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .checkboxGroupDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        align-items: center;
    }

    .formContainer {
        text-align: center;
        margin-bottom: -10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .kt-checkbox-list .kt-checkbox:last-child {
        margin-bottom: 10px;
    }
</style>